import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  SubtitleWord,
  SubtitleSentence,
  IClip,
  IClipEdit,
  IFeedScene,
} from '../../../../data/intefaces/stream.interface';
import { CaretLeftOutlined, CaretRightOutlined, LoadingOutlined } from '@ant-design/icons';
import { Image, Tooltip, Typography, Spin, Switch, message } from 'antd';
import TextPositionIcon from '../../../../assets/text-position.svg';
import BoldIcon from '../../../../assets/bold-icon.svg';
import UppercaseIcon from '../../../../assets/uppercase-icon.svg';
import ColorLetterIcon from '../../../../assets/color-letter.svg';
import StrokeLetterIcon from '../../../../assets/stroke-letter.svg';
import BackgroundLetterIcon from '../../../../assets/background-letter.svg';
import PlusIcon from '../../../../assets/plus-icon.svg';
import ReloadIcon from '../../../../assets/reload-icon.svg';
import 'antd/dist/antd.css';
import SubtitlesWord from '../subtitles-word/SubtitlesWord';
import './SubtitlesPanel.less';
import SubtitlesSelectButton from '../subtitles-select-button/SubtitlesSelectButton';
import SubtitlesMultiselectButton from '../subtitles-multiselect-button/SubtitlesMultiselectButton';
import SubtitlesColorsGroup from '../subtitles-colors-group/SubtitlesColorsGroup';
import SubtitlesDropdown from '../subtitles-dropdown/SubtitlesDropdown';
import { SliderMarks } from 'antd/lib/slider';
import { formatTime, reformatTime, useTraceUpdate } from '../../../../utils';
import { formatSeconds } from '../../../../utils/generic';
import { COLORS } from '../../../../themes/colors';
import SubtitlesEmoji from '../subtitles-emoji/SubtitlesEmoji';
import { EditorTabNames, stylePresets } from '../../../../constants/content-constants';
import { AnimatedCaptions } from '../../AnimatedCaptions';
import { BRollTab } from '../../BRollTab';
import { ReactComponent as DropdownIndicatorIcon } from '../../../../assets/dropdown-indicator-small.svg';
import { ReactComponent as SilenceIcon } from '../../../../assets/silence.svg';
import { GifsAnimationsTab } from '../../GifsAnimationsTab';
import { TextInsertTab } from '../../TextInsertTab';
import { ElementsTab } from '../../ElementsTab';
import { Templates } from '../../Templates';
import Select, { DropdownIndicatorProps, components } from 'react-select';
import SwitchButton from '../../../../components/buttons/switch/SwitchButton';
import { SelectedWordsPopup } from './SelectedWordsPopup';
import Modal from 'react-modal';
import { BrandingTab } from '../../BrandingTab';
import { BasicEditor } from '../../BasicEditor';
import { MyAssetsTab } from '../../MyAssetsTab';

import { Presets } from '../../Presets';
import { IStreamer } from '../../../../data/intefaces/streamer.interface';

interface SubtitlesPanelProps {
  clip: IClip & Partial<IClipEdit>;
  user?: IStreamer;
  sentences: SubtitleSentence[];
  rightToLeft?: boolean;
  playerRef?: React.RefObject<HTMLVideoElement> | null;
  updateSentences: any;
  updateSubtitlesSettings: any;
  subtitlesSettings: any;
  trimValues: [number, number];
  setTrimValues: (trimValues: [number, number]) => void;
  resetFilters: () => void;
  emojiEnabled?: boolean;
  loadingSubtitles?: boolean;
  optimizeEditor?: boolean;
  activeEditorTab: EditorTabNames;
  featureSliders: any[];
  subtitleState: boolean;
  allFeedScenes: IFeedScene[];
  setSubtitleState: (enabled: boolean) => void;
  setEmojiEnabled: (enabled: boolean) => void;
  updateFunction: (animationName: string) => void;
  handleDeleteFeatureSlider: (id: number) => void;
  editFeatureSlider: (featureSliderId: number, updatedFields: any) => void;
  handleChooseBRollImage: (imageUrl: string, name: string, ratio: number) => void;
  handleChooseBRollVideo: (videoUrl: string, name: string, duration: number, ratio: number) => void;
  handleChooseSocialLogo: (text: string, name: string, ratio: number) => void;
  handleChooseGifs: (videoUrl: string, name: string, duration: number, ratio: number) => void;
  handleAddTextToFeatureSliders: (textValue: string, ratio: number, fontFamily: string, fontColor: string, fontWeight: string | number, textShadow: string, fontSize: number, textTransform: string, textShadowColor: string) => void;
  handleChooseWatermark: (imageUrl: string, ratio: number, position: string) => void;
  onSeek: (time: number) => void;
  handleRemoveSilentMoments: () => void;
  makeClipFromSelectedWords: (startTime: number, endTime: number) => void;
  hideSelectedWords: (startTime: number, endTime: number) => void;
  handleSelectNoneWatermark: () => void;
}

export interface TextSettings {
  word: string;
  background: string;
  start: number;
  end: number;
  color: string;
  textShadow: string;
  fontWeight: string;
  textTransform: string;
  fontSize: string;
  isHighlighted: boolean;
  fontFamily: string;
  alignItems: string;
}

export interface ISubtitleFilterOption {
  value: string | boolean;
  label: string;
  icon?: React.ReactNode;
}

export interface ISubtitleFilterItem {
  name: string;
  options: ISubtitleFilterOption[];
}

const CUT_SUBTITLES_DELTA = 300;

const SubtitlesPanel: React.FC<SubtitlesPanelProps> = React.memo(({
  clip,
  user,
  sentences,
  playerRef,
  rightToLeft,
  updateSentences,
  updateSubtitlesSettings,
  subtitlesSettings,
  trimValues,
  setTrimValues,
  resetFilters,
  emojiEnabled,
  loadingSubtitles = false,
  optimizeEditor = false,
  activeEditorTab,
  featureSliders,
  subtitleState,
  allFeedScenes,
  updateFunction,
  handleDeleteFeatureSlider,
  editFeatureSlider,
  handleChooseBRollImage,
  handleChooseBRollVideo,
  handleAddTextToFeatureSliders,
  handleChooseSocialLogo,
  handleChooseWatermark,
  setEmojiEnabled,
  setSubtitleState,
  onSeek,
  handleRemoveSilentMoments,
  makeClipFromSelectedWords,
  hideSelectedWords,
  handleSelectNoneWatermark
}) => {
  const videoElement = document.getElementsByTagName('video')[0];
  const [currentTime, setCurrentTime] = useState(0);
  const activeSentenceRef = useRef<HTMLDivElement>(null);
  const [activeSentenceIndex, setActiveSentenceIndex] = useState(-1);
  const [levelStatus, setLevelStatus] = useState(true);
  const [counter, setCounter] = useState(0);
  const [groupLevel, setGroupLevel] = useState(sentences);
  const [initialGroupLevel, setInitialGroupLevel] = useState<any>(null);
  const [showSilentMomentsModal, setShowSilentMomentsModal] = useState(false);
  const [selectedPreset, setSelectedPreset] = useState<null | string>(null);

  const [settingsVersion, setSettingsVersion] = useState(0);
  const [currentPreset, setCurrentPreset] = useState('Premade Templates');
  const [cutSubtitles, setCutSubtitles] = useState([0, 200]);
  const [animation, setAnimation] = useState('');
  const [messageApi, contextHolder] = message.useMessage();

  const subtitlesPanelScrollRef = useRef<HTMLDivElement>(null);
  const scrollPosition = useRef<{ scrollTop: number }>({ scrollTop: 0 });

  const [selectedWordsStartTimes, setSelectedWordsStartTimes] = useState<any>([]);
  const [showSelectedTextPopup, setShowSelectedTextPopup] = useState(false);

  useEffect(() => {
    const handleMouseUp = () => {
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const selectedContent = range.cloneContents();
        const spans = selectedContent.querySelectorAll('span[data-word-id]');

        // Getting all selected words data
        const selectedWordIds = Array.from(spans).map(span => span.getAttribute('data-word-id'));

        if (selectedWordIds.length > 0) {
          // Mapping through word IDs that are stored as data attributes
          setSelectedWordsStartTimes(selectedWordIds.map(id => Number(id)));
          setShowSelectedTextPopup(true);
        }

        window.getSelection()?.removeAllRanges(); // Removing highlighting from the text
      }
    };

    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const getFirstWordObject = () => {
    const firstSentenceObject = sentences.find(sentence => sentence.words.find(word => word.start === selectedWordsStartTimes[0]));
    const firstWord = firstSentenceObject?.words.find(word => word.start === selectedWordsStartTimes[0]);
    return firstWord;
  };

  const getLastWordObject = () => {
    const lastSentenceObject = sentences.find(sentence => sentence.words.find(word => word.start === selectedWordsStartTimes[selectedWordsStartTimes.length - 1]));
    const lastWord = lastSentenceObject?.words.find(word => word.start === selectedWordsStartTimes[selectedWordsStartTimes.length - 1]);
    return lastWord;
  };

  // Remove words from clip
  const handleHideSelectedWords = () => {
    if (selectedWordsStartTimes.length > 0) {
      const firstWordObject = getFirstWordObject();
      const lastWordObject = getLastWordObject();

      if (firstWordObject && lastWordObject) {
        hideSelectedWords(firstWordObject.start / 1000, lastWordObject.end / 1000);
      } else {
        messageApi.error('Error removing words from clip. Please try again', 5);
      }
    }
  };

  // Create clip from selected words
  const handleMakeClipFromSelectedWords = () => {
    if (selectedWordsStartTimes.length > 0) {
      const firstWordObject = getFirstWordObject();
      const lastWordObject = getLastWordObject();

      if (firstWordObject && lastWordObject) {
        makeClipFromSelectedWords(firstWordObject.start / 1000, lastWordObject.end / 1000);
      } else {
        messageApi.error('Error making clip from selected words. Please try again', 5);
      }
    }
  };

  const handleCloseSelectedWordsPopup = () => {
    setShowSelectedTextPopup(false);
    setSelectedWordsStartTimes([]);
  };

  useEffect(() => {
    setTimeout(() => {
      const handleScroll = () => {
        if (subtitlesPanelScrollRef.current) {
          scrollPosition.current.scrollTop = subtitlesPanelScrollRef.current.scrollTop;
        }
      };

      const scrollElement = subtitlesPanelScrollRef.current;
      if (scrollElement) {
        scrollElement.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (scrollElement) {
          scrollElement.removeEventListener('scroll', handleScroll);
        }
      };
    }, 2000);
  }, []);

  useEffect(() => {
    const scrollElement = subtitlesPanelScrollRef.current;
    if (scrollElement) {
      scrollElement.scrollTop = scrollPosition.current.scrollTop;
    }
  });


  useEffect(() => {
    setGroupLevel(sentences);
  }, [sentences]);

  /* Fix for group level */
  useEffect(() => {
    if (sentences.find(sent => sent.words.length > 1)) {
      setInitialGroupLevel(sentences);
    }
  }, [sentences]);


  const presets = {
    name: 'presets',
    options: [
      { value: 'Default', label: 'Default', icon: 'AliwangwangOutlined' },
      { value: 'SkyHigh', label: 'SkyHigh', icon: 'AliwangwangOutlined' },
      { value: 'Mono', label: 'Mono', icon: 'AliwangwangOutlined' },
      { value: 'Ruby', label: 'Ruby', icon: 'AliwangwangOutlined' },
      { value: 'Sunshin', label: 'Sunshin', icon: 'AliwangwangOutlined' },
      { value: 'GldGlow', label: 'GldGlow', icon: 'AliwangwangOutlined' },
      { value: 'Emerald', label: 'Emerald', icon: 'AliwangwangOutlined' },
      { value: 'Nghtvlt', label: 'Nghtvlt', icon: 'AliwangwangOutlined' },
      { value: 'SpcShad', label: 'SpcShad', icon: 'AliwangwangOutlined' },
      { value: 'GrnGlow', label: 'GrnGlow', icon: 'AliwangwangOutlined' },
      { value: 'MidnightBlue', label: 'MidnightBlue', icon: 'AliwangwangOutlined' },
      { value: 'RoseGold', label: 'RoseGold', icon: 'AliwangwangOutlined' },
      { value: 'OceanWave', label: 'OceanWave', icon: 'AliwangwangOutlined' },
      { value: 'LimeLight', label: 'LimeLight', icon: 'AliwangwangOutlined' },
      { value: 'VintageCharm', label: 'VintageCharm', icon: 'AliwangwangOutlined' },
      // { value: 'Winter', label: 'Winter', icon: 'AliwangwangOutlined' },
      { value: 'NeonPink', label: 'NeonPink', icon: 'AliwangwangOutlined' },
      { value: 'DarkForest', label: 'DarkForest', icon: 'AliwangwangOutlined' },
      { value: 'SapphireGlow', label: 'SapphireGlow', icon: 'AliwangwangOutlined' },
      { value: 'GoldenSunset', label: 'GoldenSunset', icon: 'AliwangwangOutlined' },

    ],
  };


  // Select dropdown options
  const selectData: ISubtitleFilterItem[] = [
    {
      name: 'fontFamily',
      options: [
        { value: 'Impact', label: 'Impact', icon: 'AliwangwangOutlined' },
        { value: 'Arial', label: 'Arial', icon: 'AliwangwangOutlined' },
        { value: 'Georgia', label: 'Georgia', icon: 'AliwangwangOutlined' },
        {
          value: 'Times New Roman',
          label: 'Times New Roman',
          icon: 'AliwangwangOutlined',
        },
        { value: 'Verdana', label: 'Verdana', icon: 'AliwangwangOutlined' },
        { value: 'Lato', label: 'Lato', icon: 'AliwangwangOutlined' },
        { value: 'Roboto', label: 'Roboto', icon: 'AliwangwangOutlined' },
        { value: 'Poppins', label: 'Poppins', icon: 'AliwangwangOutlined' },
        {
          value: 'Montserrat',
          label: 'Montserrat',
          icon: 'AliwangwangOutlined',
        },
        {
          value: 'Roboto Condensed',
          label: 'Roboto Condensed',
          icon: 'AliwangwangOutlined',
        },
        { value: 'Open Sans', label: 'Open Sans', icon: 'AliwangwangOutlined' },
        { value: 'Courier New', label: 'Courier New', icon: 'AliwangwangOutlined' },
        { value: 'Helvetica', label: 'Helvetica', icon: 'AliwangwangOutlined' },
        { value: 'Garamond', label: 'Garamond', icon: 'AliwangwangOutlined' },
        { value: 'Arial Narrow', label: 'Arial Narrow', icon: 'AliwangwangOutlined' },
        {
          value: 'Bebas Neue',
          label: 'Bebas Neue',
          icon: 'AliwangwangOutlined',
        },
        {
          value: 'Belanosima',
          label: 'Belanosima',
          icon: 'AliwangwangOutlined',
        },
        { value: 'Caprasimo', label: 'Caprasimo', icon: 'AliwangwangOutlined' },
        {
          value: 'Red Hat Display',
          label: 'Red Hat Display',
          icon: 'AliwangwangOutlined',
        },
        { value: 'Work Sans', label: 'Work Sans', icon: 'AliwangwangOutlined' },
        { value: 'Kanit', label: 'Kanit', icon: 'AliwangwangOutlined' },
        { value: 'Oswald', label: 'Oswald', icon: 'AliwangwangOutlined' },
        { value: 'Koulen', label: 'Koulen', icon: 'AliwangwangOutlined' },
        { value: 'Staatliches', label: 'Staatliches', icon: 'AliwangwangOutlined' },
        { value: 'Merriweather', label: 'Merriweather', icon: 'AliwangwangOutlined' },
        { value: 'Raleway', label: 'Raleway', icon: 'AliwangwangOutlined' },
        { value: 'Exo 2', label: 'Exo 2', icon: 'AliwangwangOutlined' },
        { value: 'Qwitcher Grypen', label: 'Qwitcher Grypen', icon: 'AliwangwangOutlined' },
      ],
    },
    {
      name: 'background',
      options: [
        { value: '#000000', label: 'Black', icon: BackgroundLetterIcon },
        { value: '#EEEEEE', label: 'grey', icon: BackgroundLetterIcon },
        { value: '#284828', label: 'green', icon: BackgroundLetterIcon },
        { value: 'none', label: 'none', icon: BackgroundLetterIcon },
      ],
    },
    {
      name: 'color',
      options: [
        { value: '#04F827', label: 'green', icon: ColorLetterIcon },
        { value: '#FFFD03', label: 'yellow', icon: ColorLetterIcon },
        { value: '#FFFFFF', label: 'white', icon: ColorLetterIcon },
      ],
    },
    {
      name: 'fontSize',
      options: [
        { value: 'Small', label: 'S' },
        { value: 'Medium', label: 'M' },
        { value: 'Big', label: 'L' },
        { value: 'Huge', label: 'XL' },
      ],
    },
    {
      name: 'textShadow',
      options: [
        { value: 'None', label: 'None', icon: StrokeLetterIcon },
        { value: 'Heavy', label: 'Heavy', icon: StrokeLetterIcon },
        { value: 'Medium', label: 'Medium', icon: StrokeLetterIcon },
        { value: 'Light', label: 'Light', icon: StrokeLetterIcon },
      ],
    },
    {
      name: 'fontWeight',
      options: [
        { value: '700', label: 'bold', icon: BoldIcon },
        { value: '500', label: 'normal', icon: BoldIcon },
      ],
    },
    {
      name: 'textTransform',
      options: [
        { value: 'none', label: 'none', icon: UppercaseIcon },
        { value: 'uppercase', label: 'uppercase', icon: UppercaseIcon },
      ],
    },
    {
      name: 'alignItems',
      options: [
        { value: 'Top', label: 'Top', icon: TextPositionIcon },
        { value: 'Middle', label: 'Middle', icon: TextPositionIcon },
        { value: 'Bottom', label: 'Bottom', icon: TextPositionIcon },
      ],
    },
    {
      name: 'wordLevel',
      options: [
        { value: false, label: 'Word Level', icon: null },
        { value: true, label: 'Group Level', icon: null },
      ],
    },
  ];

  // Handle group level change (word level or group level)
  const handleGroupLevelChange = (updatedSentences: SubtitleSentence[]) => {
    setGroupLevel(updatedSentences);
  };

  /* Fix for group level (commented out the code) */

  // Update group level sentences when sentences prop changes
  // useEffect(() => {
  //   if (counter < 2) {
  //     handleGroupLevelChange(sentences);
  //     setCounter((prevCounter) => prevCounter + 1);
  //   }
  //   if (counter === 2) { // this code was changing to word level whenever it was opened for the first time
  //     handleChangeCaptionGrouping(false);
  //     setCounter((prevCounter) => prevCounter + 1);
  //   }
  // }, [sentences, counter]);

  // Update active sentence and scroll to it based on video time
  useEffect(() => {
    const handleTimeUpdate = () => {
      if (videoElement) {
        const newTime = Math.floor(videoElement.currentTime * 1000); // Round the current time to milliseconds
        setCurrentTime(newTime);
        const activeSentence = sentences.find((sentence) => {
          return newTime >= sentence.start - 50 && newTime < sentence.end;
        });

        if (activeSentence) {
          const activeIndex = sentences.indexOf(activeSentence);
          setActiveSentenceIndex(activeIndex);
          scrollToActiveSentence(activeIndex);
        }
      }
    };

    const scrollToActiveSentence = (index: number) => {
      const sentenceElement = document.getElementById(`sentence-${index}`);
      const containerElement = document.getElementById('subtitles-container');
      if (
        sentenceElement instanceof HTMLElement &&
        containerElement instanceof HTMLElement
      ) {
        const sentenceRect = sentenceElement.getBoundingClientRect();
        const containerRect = containerElement.getBoundingClientRect();

        if (
          sentenceRect.top < containerRect.top ||
          sentenceRect.bottom > containerRect.bottom
        ) {
          containerElement.scrollTop =
            sentenceElement.offsetTop - containerElement.offsetTop;
        }
      }
    };

    if (videoElement) {
      videoElement.addEventListener('timeupdate', handleTimeUpdate);

      return () => {
        videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [playerRef, videoElement, sentences]);


  // Handle word change in a sentence
  const handleWordChange = useCallback((
    value: string | number,
    param: keyof SubtitleWord,
    sentenceIndex: number,
    wordIndex: number,
  ) => {
    const updatedSentences = [...sentences];
    const updatedSentence = { ...updatedSentences[sentenceIndex] };

    if (updatedSentence.words) {
      const updatedWord = {
        ...updatedSentence.words[wordIndex],
        [param]: value,
      };
      updatedSentence.words[wordIndex] = updatedWord;
      updatedSentences[sentenceIndex] = updatedSentence;
      updateSentences(updatedSentences);

      if (playerRef?.current && playerRef?.current?.querySelector('video')) {
        const videoElement = playerRef?.current.querySelector('video');
        const startTime = updatedWord.start / 1000;
        if (videoElement) {
          videoElement.currentTime = startTime;
          setCurrentTime(startTime * 1000); // Update the current time immediately after changing the word
        }
      }
    }
  }, [sentences.length > 1]);

  // Handle time range change in a sentence
  const handleTimeRangeChange = (
    value: number,
    sentenceIndex: number,
    isStart: boolean,
    spanRef: any
  ) => {
    const updatedSentences = [...sentences];
    const updatedSentence = { ...updatedSentences[sentenceIndex] };
    let inCorrectRange = true;

    updatedSentences.forEach((sen, i) => {
      if (i !== sentenceIndex) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if ((value >= sen.start && value <= sen.end) || (value < 0 || value > videoElement?.duration * 1000)) {
          inCorrectRange = false;
          if (spanRef && spanRef.current) {
            spanRef.current.innerHTML = isStart ? formatTime(updatedSentence.start) : formatTime(updatedSentence.end);
          }
          return;
        }
      }
    });

    if (!inCorrectRange) return null;

    if (updatedSentence && value) {
      if (isStart) {
        updatedSentence.start = value;
      } else {
        updatedSentence.end = value;
      }

      if (updatedSentence?.words?.length === 1) {
        const updatedWord = {
          ...updatedSentence.words[0],
        };
        if (isStart) {
          updatedWord.start = value;
        } else {
          updatedWord.end = value;
        }
        updatedSentence.words[0] = updatedWord;
      }

      updatedSentences[sentenceIndex] = updatedSentence;
      updateSentences(updatedSentences);
    }
  };

  const TemplateChange = (box: any) => {
    Object.entries(box).forEach(([property, value]: [string, any]) => {
      if (typeof value !== 'undefined' && property in subtitlesSettings) {
        handleSubtitlesChange(property as keyof TextSettings, value);
      }
    });
  };


  // Handle subtitles settings change
  const handleSubtitlesChange = (
    param: keyof TextSettings,
    value: string | number
  ) => {
    const updatedSentences = [...sentences];
    if (param in subtitlesSettings) {
      updatedSentences.forEach((sentence) => {
        if (sentence.words) {
          sentence.words = sentence.words.map((word) => {
            if (word[param] === subtitlesSettings[param] || param === 'fontFamily') {
              return { ...word, [param]: value };
            }
            return word;
          });
        }
      });

      const newGlobalSettings = {
        ...subtitlesSettings,
        [param]: value
      };
      // Update the settings version to trigger a re-render
      setSettingsVersion((prevVersion) => prevVersion + 1);

      updateSubtitlesSettings(newGlobalSettings);
      updateSentences(updatedSentences);
    }
  };
  const changePreset = (value: any) => {
    const presetsStyle = [

      {
        name: 'Default',
        style: {
          background: '#000000',
          color: '#FFFFFF',
          fontFamily: 'Impact',
          fontWeight: 'medium',
          textTransform: 'uppercase',
          textShadow: 'Heavy',
          fontSize: 'Big',
        },
      },
      {
        name: 'RedAlrt',
        style: {
          background: '#F44336',
          color: '#FFEB3B',
          fontFamily: 'Oswald',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textShadow: 'Medium',
          fontSize: 'Medium'
        }
      },
      {
        name: 'Ruby',
        style: {
          background: '#000000',
          color: '#E0115F',
          fontFamily: 'Arial',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textShadow: 'Heavy',
          fontSize: 'Big',
        },
      },
      {
        name: 'Emerald',
        style: {
          background: '#284828',
          color: '#04F827',
          fontFamily: 'Georgia',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textShadow: 'Medium',
          fontSize: 'Huge',
        },
      },
      {
        name: 'Silver',
        style: {
          background: '#C0C0C0',
          color: '#222222',
          fontFamily: 'Times New Roman',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textShadow: 'Medium',
          fontSize: 'Medium',
        },
      },
      {
        name: 'Sneakers',
        style: {
          background: '#673AB7',
          color: '#FFEB3B',
          fontFamily: 'Bebas Neue',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textShadow: 'Heavy',
          fontSize: 'Medium'
        }
      },
      {
        name: 'Sunshin',
        style: {
          background: '#FFC107',
          color: '#2196F3',
          fontFamily: 'Open Sans',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textShadow: 'Medium',
          fontSize: 'Medium'
        }
      },
      {
        name: 'Mono',
        style: {
          background: '#FFFFFF',
          color: '#000000',
          fontFamily: 'Arial',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textShadow: 'Medium',
          fontSize: 'Medium'
        }
      },
      {
        name: 'SkyHigh',
        style: {
          background: '#2196F3',
          color: '#FFFFFF',
          fontFamily: 'Roboto',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textShadow: 'Medium',
          fontSize: 'Medium'
        }
      },
      {
        name: 'Tangeri',
        style: {
          background: '#FF9800',
          color: '#000000',
          fontFamily: 'Montserrat',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textShadow: 'Medium',
          fontSize: 'Medium'
        }
      },
      {
        name: 'GrnGlow',
        style: {
          background: '#4CAF50',
          color: '#FFFFFF',
          fontFamily: 'Lato',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textShadow: 'Medium',
          fontSize: 'Huge'
        }
      },
      {
        name: 'SpcShad',
        style: {
          background: '#FF5722',
          color: '#FFFFFF',
          fontFamily: 'Open Sans',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textShadow: 'Medium',
          fontSize: 'Medium'
        }
      },
      {
        name: 'NghtVlt',
        style: {
          background: '#673AB7',
          color: '#FFFFFF',
          fontFamily: 'Kanit',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textShadow: 'Medium',
          fontSize: 'Medium'
        }
      },
      {
        name: 'BlBliss',
        style: {
          background: '#03A9F4',
          color: '#FFFFFF',
          fontFamily: 'Work Sans',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textShadow: 'Medium',
          fontSize: 'Medium'
        }
      },
      {
        name: 'GldGlow',
        style: {
          background: '#FFC107',
          color: '#FFFFFF',
          fontFamily: 'Poppins',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textShadow: 'Medium',
          fontSize: 'Medium'
        }
      },
      {
        name: 'MidnightBlue',
        style: {
          background: '#003366',
          color: '#FFFFFF',
          fontFamily: 'Courier New',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textShadow: 'Medium',
          fontSize: 'Huge',
        },
      },
      {
        name: 'RoseGold',
        style: {
          background: '#B76E79',
          color: '#FFFFFF',
          fontFamily: 'Serif',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textShadow: 'Light',
          fontSize: 'Huge',
        },
      },
      {
        name: 'OceanWave',
        style: {
          background: '#00BFFF',
          color: '#f0f8ff',
          fontFamily: 'Verdana',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textShadow: 'Medium',
          fontSize: 'Medium',
        },
      },
      {
        name: 'LimeLight',
        style: {
          background: '#00FF00',
          color: '#000000',
          fontFamily: 'Helvetica',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textShadow: 'Medium',
          fontSize: 'Huge',
        },
      },
      {
        name: 'VintageCharm',
        style: {
          background: '#F5F5DC',
          color: '#8B4513',
          fontFamily: 'Garamond',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textShadow: 'Medium',
          fontSize: 'Huge',
        },
      },
      {
        name: 'Winter',
        style: {
          background: '#E0FFFF',
          color: '#000080',
          fontFamily: 'Caprasimo',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textShadow: 'Medium',
          fontSize: 'Medium',
        },
      },
      {
        name: 'NeonPink',
        style: {
          background: '#FF1493',
          color: '#FFFFFF',
          fontFamily: 'Impact',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textShadow: 'Medium',
          fontSize: 'Medium',
        },
      },
      {
        name: 'DarkForest',
        style: {
          background: '#228B22',
          color: '#F0E68C',
          fontFamily: 'Arial Narrow',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textShadow: 'Medium',
          fontSize: 'Medium',
        },
      },
      {
        name: 'SapphireGlow',
        style: {
          background: '#0F52BA',
          color: '#FFFFFF',
          fontFamily: 'Koulen',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textShadow: 'Medium',
          fontSize: 'Medium',
        },
      },
      {
        name: 'GoldenSunset',
        style: {
          background: '#FFD700',
          color: '#8B0000',
          fontFamily: 'Times New Roman',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textShadow: 'Medium',
          fontSize: 'Medium',
        },
      }

    ];

    const selectedPreset = presetsStyle.find((preset) => preset.name === value);

    if (!selectedPreset) {
      return; // Invalid preset value
    }
    setCurrentPreset(value);
    const presetValues = selectedPreset.style;

    Object.entries(presetValues).forEach(([property, value]) => {
      if (property in subtitlesSettings) {
        handleSubtitlesChange(property as keyof TextSettings, value);
      }
    });



    const newGlobalSettings = {
      ...subtitlesSettings,
      ...presetValues,
    };

    updateSubtitlesSettings(newGlobalSettings);
  };

  const handleChangePreset = (selectedPreset: typeof stylePresets[0]) => {
    if (!selectedPreset) {
      return; // Invalid preset value
    }
    const presetValues = selectedPreset.style;

    setSelectedPreset(selectedPreset.name);

    Object.entries(presetValues).forEach(([property, value]) => {
      if (property in subtitlesSettings) {
        handleSubtitlesChange(property as keyof TextSettings, value as any);
      }
      if (property === 'wordLevel') {
        handleChangeCaptionGrouping(Boolean(value === 'true'));
      }
      if (property === 'captions_animation_type') {
        updateFunction(value as string);
      }
    });

    const newGlobalSettings = {
      ...subtitlesSettings,
      ...presetValues,
    };

    updateSubtitlesSettings(newGlobalSettings);
  };


  // By Word / Sentence
  // Handle dropdown change (group level or word level)
  const handleChangeCaptionGrouping = (value: boolean) => {
    const result: {
      start: number;
      end: number;
      text: string;
      words: SubtitleWord[];
    }[] = [];
    if (!value) {
      groupLevel.forEach((sentence) => {
        if (sentence.words) {
          sentence.words.forEach((word) => {
            if (word.word.trim() !== '') {
              result.push({
                start: word.start,
                end: word.end,
                text: word.word,
                words: [word],
              });
            }
          });
        }
      });
      updateSentences(result);
    } else {
      /* Fix for group level */
      if (initialGroupLevel) {
        updateSentences(initialGroupLevel);
      } else {
        updateSentences(groupLevel);
      }
    }
    setLevelStatus(value);

    const newGlobalSettings = {
      ...subtitlesSettings,
      ['wordLevel']: value,
    };
    updateSubtitlesSettings(newGlobalSettings);
  };

  const getItem = (name: keyof TextSettings) =>
    selectData.find((i) => i.name === name);

  const handleAddFontPreset = () => {
    return null;
  };

  const handleResetFilters = () => {
    resetFilters();
  };

  const strokeMarks: SliderMarks = {
    0: 'None',
    1: 'Medium',
    2: 'Heavy',
  };

  const alignItemsOptionsForGroupLevel = {
    name: 'alignItems',
    options: [
      { value: 'Middle', label: 'Middle', icon: TextPositionIcon },
      { value: 'Bottom', label: 'Bottom', icon: TextPositionIcon },
    ],
  };

  const fontSizeOptionsForGroupLevel = {
    name: 'fontSize',
    options: [
      { value: 'Small', label: 'S' },
      { value: 'Medium', label: 'M' },
      { value: 'Big', label: 'L' },
      { value: 'Huge', label: 'XL' },
    ],
  };

  const textFeatureSliders = featureSliders.filter(slider => slider?.type === 'text');
  const watermarkFeatureSliders = featureSliders.filter(slider => slider?.type === 'watermark');
  const watermarkFeatureSlider = watermarkFeatureSliders.length > 0 ? watermarkFeatureSliders[0] : null;

  const checkHiddenWord = (word: SubtitleWord) => {
    const sceneWithWord = allFeedScenes.find(scene => scene.start_time_in_event <= word.start / 1000 && scene.end_time_in_event > word.start / 1000);

    return sceneWithWord?.hidden_scene;
  };


  // Display content depending on an active tab
  const getActiveTabContent = (activeTab: EditorTabNames) => {
    if (activeTab === EditorTabNames.BASIC_EDITOR) return null;
    else if (activeTab === EditorTabNames.PRESETS) return <Presets user={user} selectedPreset={selectedPreset} handleChangePreset={handleChangePreset} setSelectedPreset={setSelectedPreset} clip={clip} />;
    else if (activeTab === EditorTabNames.ANIMATED_CAPTIONS) return <AnimatedCaptions user={user} updateFunction={updateFunction} clip={clip} />;
    else if (activeTab === EditorTabNames.FONT_DESIGN) return <Templates user={user} handleChangeTemplate={changePreset} handleSubtitlesChange={handleSubtitlesChange} subtitlesSettings={subtitlesSettings} handleChangeCaptionGrouping={handleChangeCaptionGrouping} />;
    else if (activeTab === EditorTabNames.B_ROLL) return <BRollTab user={user} handleChooseImage={handleChooseBRollImage} handleChooseVideo={handleChooseBRollVideo} />;
    else if (activeTab === EditorTabNames.GIFS) return <GifsAnimationsTab user={user} handleChooseGif={handleChooseBRollImage} />;
    else if (activeTab === EditorTabNames.ELEMENTS) return <ElementsTab user={user} handleChooseLogo={handleChooseSocialLogo} />;
    else if (activeTab === EditorTabNames.TEXT_INSERT) return <TextInsertTab user={user} textFeatureSliders={textFeatureSliders} handleAddTextToFeatureSliders={handleAddTextToFeatureSliders} editFeatureSlider={editFeatureSlider} handleDeleteFeatureSlider={handleDeleteFeatureSlider} clipDescription={clip?.description} />;
    else if (activeTab === EditorTabNames.BRANDING) return <BrandingTab user={user} handleSelectNoneWatermark={handleSelectNoneWatermark} watermarkFeatureSlider={watermarkFeatureSlider} handleChooseWatermark={handleChooseWatermark} handleDeleteFeatureSlider={handleDeleteFeatureSlider} />;
    else if (activeTab === EditorTabNames.MY_ASSETS) return <MyAssetsTab handleDeleteFeatureSlider={handleDeleteFeatureSlider} handleChooseImage={handleChooseBRollImage} handleChooseVideo={handleChooseBRollVideo} handleChooseWatermark={handleChooseWatermark} />;
    else return <h2>Coming soon...</h2>;
  };

  const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => {
    return (
      <components.DropdownIndicator {...props}>
        <DropdownIndicatorIcon />
      </components.DropdownIndicator>
    );
  };

  const Option = (props: any) => {
    return (
      <div style={{ fontFamily: props.value }}>
        <components.Option {...props} />
      </div>
    );
  };

  const handleWordClick = (wordStart: number) => {
    onSeek(wordStart / 1000);
  };

  const doesSilentMomentsExist = useCallback(() => {
    if (clip.silent_moments) {
      const parsed = JSON.parse(clip.silent_moments);
      if (Array.isArray(parsed)) {
        if (parsed.length > 0) {
          return true;
        } else return false;
      } else return false;
    } else {
      return false;
    }
  }, [clip?.silent_moments]);

  const handleCloseSilentMomentsModal = () => {
    setShowSilentMomentsModal(false);
  }

  const handleClickSilentMomentsButton = () => {
    if (doesSilentMomentsExist()) {
      setShowSilentMomentsModal(true);
    } else {
      messageApi.info('No silent moments detected');
    }
  }

  const handleClickConfirmSilentMoments = () => {
    handleRemoveSilentMoments();
    handleCloseSilentMomentsModal();
  }

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {contextHolder}
      {showSelectedTextPopup &&
        <SelectedWordsPopup
          onChange={handleWordChange}
          handleClosePopup={handleCloseSelectedWordsPopup}
          hideSelectedWords={handleHideSelectedWords}
          makeClipFromSelectedWords={handleMakeClipFromSelectedWords}
          selectedWordsStartTimes={selectedWordsStartTimes}
          sentences={sentences}
        />
      }
      <Modal
        isOpen={showSilentMomentsModal}
        className="Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleCloseSilentMomentsModal}
      >
        <div style={{ width: 430 }} className="glass-modal">
          <span onClick={handleCloseSilentMomentsModal} className="icon-close">X</span>
          <h1>Silent Moments</h1>
          <p>We are about to make changes to the scenes. Please notice we will not change scenes settings, but we&apos;ll divide scenes differently and skip them. You can always bring back skipped scenes by clicking on the scene.</p>

          <div className="buttons">
            <div onClick={handleCloseSilentMomentsModal} style={{ minWidth: 120 }} className="cancel-btn">Cancel</div>
            <div onClick={handleClickConfirmSilentMoments} className="confirm-btn">
              <span>Remove Silent Moments</span>
              <SilenceIcon />
            </div>
          </div>

        </div>
      </Modal>
      <Select
        className="font-family-select"
        classNamePrefix="font-family-select-prefix"
        styles={{
          singleValue: (baseStyles, state) => ({
            ...baseStyles,
            fontFamily: subtitlesSettings['fontFamily'],
          }),
        }}
        value={getItem('fontFamily')?.options.find(el => el.value === subtitlesSettings['fontFamily'])}
        onChange={(result: any) => handleSubtitlesChange('fontFamily', result.value)}
        options={getItem('fontFamily')?.options}
        components={{ DropdownIndicator, Option }}
        placeholder='Fonts'
      />

      {activeEditorTab === EditorTabNames.BASIC_EDITOR &&
        <BasicEditor
          loadingSubtitles={loadingSubtitles}
          handleClickSilentMomentsButton={handleClickSilentMomentsButton}
          doesSilentMomentsExist={doesSilentMomentsExist}
          subtitlesPanelScrollRef={subtitlesPanelScrollRef}
          trimValues={trimValues}
          rightToLeft={rightToLeft}
          sentences={sentences}
          activeSentenceIndex={activeSentenceIndex}
          activeSentenceRef={activeSentenceRef}
          handleWordClick={handleWordClick}
          checkHiddenWord={checkHiddenWord}
          optimizeEditor={optimizeEditor}
          setTrimValues={setTrimValues}
          emojiEnabled={emojiEnabled}
          animation={animation}
          selectedWordsStartTimes={selectedWordsStartTimes}
          currentTime={currentTime}
          handleTimeRangeChange={handleTimeRangeChange}
          handleWordChange={handleWordChange}
        />
      }


      {getActiveTabContent(activeEditorTab)}

      {/* Subtitles container */}


      <div className='bottom-buttons__container'>
        <div className='subtitles-right-divider'>
          <SubtitlesSelectButton
            name='alignItems'
            item={getItem('alignItems')}
            handleChange={handleSubtitlesChange}
            value={subtitlesSettings['alignItems']}
          />
        </div>
        {/* <div className='subtitles-right-divider'>
          <SubtitlesDropdown
            title='Grouping'
            value={subtitlesSettings['wordLevel'] ? 'Group Level' : 'Word Level'}
            // value={subtitlesSettings['animate']}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onChange={handleChangeCaptionGrouping}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            options={getItem('wordLevel')?.options}
          />
        </div> */}
        <div className='subtitles-right-divider'>
          <div className='bottom-control__switch-container'>
            <span>Emojis</span>
            <Switch
              className="ios-switch"
              checked={emojiEnabled}
              onChange={(value: boolean) => setEmojiEnabled(value)}
            />
          </div>
        </div>
        <div className='subtitles-right-divider'>
          <div className='bottom-control__switch-container'>
            <span>Subtitles</span>
            <Switch
              className="ios-switch"
              checked={subtitleState}
              onChange={(value: boolean) => setSubtitleState(value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

SubtitlesPanel.displayName = 'SubtitlesPanel';

interface IIconButton {
  icon: React.ReactNode;
  onClick: () => void;
  isReload?: boolean;
  disabled?: boolean;
  isTopControll?: boolean;
}

export const IconButton = ({
  icon,
  onClick,
  isReload = false,
  disabled = false,
  isTopControll = false,
}: IIconButton) => {
  return (
    <div
      onClick={onClick}
      className={`subtitles-icon-button ${!isTopControll ? 'subtitles-bottom-divider' : ''} ${isTopControll ? 'icon-button-top-controll' : ''} 
      ${disabled ? 'icon-button-disabled' : ''} ${isReload ? 'reload-button' : ''}`}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Image src={icon} preview={false} />
    </div>
  );
};

interface ITimeRangeSpan {
  time: string;
  onChange?: (value: number, spanRef: any) => void;
  isFirstWord?: boolean;
  isLastWord?: boolean;
  setTrimValues: (trim: [number, number]) => void;
  trimValues: [number, number]
};

export const TimeRangeSpan = ({ time, onChange, isFirstWord, isLastWord, trimValues, setTrimValues }: ITimeRangeSpan) => {
  const styles = useMemo(() => {
    return {
      container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        color: COLORS.BLACK,
      },
      description: {
        fontSize: 10,
        fontWeight: 400,
        lineHeight: '10.6px',
        textAlign: 'center',
        maxWidth: 65
      },
      controls: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: 6,
        alignItems: 'center'
      }
    } as const;
  }, []);

  const spanRef = useRef<HTMLSpanElement>(null);
  // Handler for time range change events
  const handleTimeRangeChange = (wrd: string) => {
    if (spanRef.current && onChange) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (wrd?.target.textContent) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange(reformatTime(wrd?.target.textContent), spanRef);
      }
    }
  };

  const handleAddMilliseconds = (ms: number) => {
    isLastWord && setTrimValues([trimValues[0], (reformatTime(time) + ms) / 1000]);
    onChange && onChange(reformatTime(time) + ms, spanRef);
  };

  const handleRemoveMilliseconds = (ms: number) => {
    isFirstWord && setTrimValues([(reformatTime(time) - ms) / 1000, trimValues[1]]);
    onChange && onChange(reformatTime(time) - ms, spanRef);
  };

  const rangePicker = (
    <div style={styles.container}>
      <span style={styles.description}>50 milliseconds</span>
      <div style={styles.controls}>
        <CaretLeftOutlined onClick={() => handleRemoveMilliseconds(50)} />
        <CaretRightOutlined onClick={() => handleAddMilliseconds(50)} />
      </div>
    </div>
  );

  return (
    <Tooltip overlayClassName='sub-range-picker' title={rangePicker} trigger="click" color="rgba(255, 255, 255, 0.7)">
      {/* <Tooltip
        title='Change Time'
        placement="top"
        mouseEnterDelay={1}
        mouseLeaveDelay={0.1}
        overlayClassName="popup-container small-popup"
      > */}
      <span
        ref={spanRef}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onFocus={handleTimeRangeChange}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onClick={handleTimeRangeChange}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onBlur={handleTimeRangeChange}
        contentEditable
        suppressContentEditableWarning
      >{time}</span>
      {/* </Tooltip > */}
    </Tooltip>
  );
};

interface IAddMarginSpan {
  time: string;
  onChange?: (value: number, spanRef: any) => void;
  isStart?: boolean;
  setTrimValues: (trim: [number, number]) => void;
  trimValues: [number, number]
};


const AddMarginComponent = ({ time, onChange, isStart, trimValues, setTrimValues }: IAddMarginSpan) => {

  const handleAddMilliseconds = (ms: number) => {
    setTrimValues([trimValues[0], (reformatTime(time) + ms) / 1000]);
    onChange && onChange(reformatTime(time) + ms, null);
  };

  const handleRemoveMilliseconds = (ms: number) => {
    setTrimValues([(reformatTime(time) - ms) / 1000, trimValues[1]]);
    onChange && onChange(reformatTime(time) - ms, null);
  };

  return (
    <div
      // onClick={isStart ? () => handleRemoveMilliseconds(30) : () => handleAddMilliseconds(30)}
      className='color-item'
      style={{ backgroundColor: COLORS.BACKGROUND_DARK_GRAY, marginRight: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'default' }}
    >
      <Typography style={{ color: COLORS.WHITE, fontSize: 12, lineHeight: '10px' }}>30</Typography>
    </div>
  );
};

export default SubtitlesPanel;
