import React, { useEffect, useState, useContext, useMemo, memo, useRef } from 'react';
import { COLORS } from '../../../themes/colors';
import { ReactComponent as ScissorsIcon } from '../../../assets/scissors.svg';
import { ReactComponent as RewindIcon } from '../../../assets/player_rewind.svg';
import { ReactComponent as PlayIcon } from '../../../assets/play.svg';
import { ReactComponent as PauseIcon } from '../../../assets/pause.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/recycle-bin.svg';
import { ReactComponent as FiveSecondBackIcon } from '../../../assets/five_second_back.svg';
import { ReactComponent as FiveSecondForwardIcon } from '../../../assets/five_second_forward.svg';
import { ReactComponent as RevertIcon } from '../../../assets/revert.svg';
import { ReactComponent as IconArrowLeft } from '../../../assets/arrow_left.svg';
import { ReactComponent as MergeIcon } from '../../../assets/arrow_merge.svg';
import { ReactComponent as BinIcon } from '../../../assets/bin_gray.svg';
import { ReactComponent as CutHorizontalIcon } from '../../../assets/cut_horizontal.svg';
import { formatSeconds, formatSecondsWithMilliseconds } from '../../../utils/generic';
import Slider, { SliderProps } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { EditorFeatureSlider } from './EditorFeatureSlider';
import { Tooltip, message } from 'antd';
import { IFeedScene } from '../../../data/intefaces/stream.interface';
import { useTraceUpdate } from '../../../utils';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import './VideoTimeline.less';
import raf from 'rc-util/lib/raf';
import Modal from 'react-modal';
import { Toast } from '../../toast/Toast';
import TooltipSlider from './TooltipSlider';

import { WAVEFORM_EXAMPLE } from '../../../constants/content-constants';
import SoundWave from '../../../pages/Editor/SoundWave/SoundWave';

export interface IVideoTimeline {
  progress: number;
  duration: number;
  onPlayPause: (play: boolean) => void;
  playing: boolean;
  onSeek: (value: number) => void;
  featureSliders: any[];
  setFeatureSliders: any;
  handleDeleteFeatureSlider?: (id: any) => void;
  handleSliderTimeChange?: (id: any, time: number | number[]) => void;
  trimValuesProp: any;
  trimValues: any;
  handleTrimmingBarChange: any;
  handleSplitVideo?: (splitTime: number) => void;
  // feedScenesBreakpoints?: number[];
  handleDeleteFeedScene?: (startTime: number) => void;
  handleHideFeedScene?: (startTime: number) => void;
  handleRevertFeedScene?: (startTime: number) => void;
  handleDeleteAllScenes?: () => void;
  allFeedScenes: IFeedScene[];
  currentFeedScene: IFeedScene;
  playerLoadedSeconds: number;
  disableScenes?: boolean;
  isEditorFocused?: boolean
  audioWaveform?: any;
}

const SEEK_VALUE = 0.5;
const DEFAULT_TIMELINE_COMPONENT_HEIGHT = 305;
const TIMELINE_SECTIONS_NUMBER = 14;

export const VideoTimeline = memo(({ progress, audioWaveform, allFeedScenes, duration, currentFeedScene, onPlayPause, playing, onSeek, featureSliders, disableScenes, handleDeleteFeatureSlider, setFeatureSliders, handleSliderTimeChange, trimValuesProp, trimValues, handleTrimmingBarChange, handleSplitVideo, handleDeleteFeedScene, handleHideFeedScene, handleRevertFeedScene, handleDeleteAllScenes, playerLoadedSeconds, isEditorFocused }: IVideoTimeline) => {
  const [currentTime, setCurrentTime] = useState(progress);
  const [currentTrim, setCurrentTrim] = useState(trimValuesProp || trimValues);
  const [showDeleteAllScenesModal, setShowDeleteAllScenesModal] = useState(false);
  const [hoverOnDelete, setHoverOnDelete] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [showArrowsProTip, setShowArrowsProTip] = useState(false);
  const [TIMELINE_COMPONENT_HEIGHT, setTimeLineComponentHeight] = useState(DEFAULT_TIMELINE_COMPONENT_HEIGHT)
  const durationRef = useRef<number>(duration); // Ref to store the latest duration value

  useEffect(() => {
    if (!Array.isArray(audioWaveform) && TIMELINE_COMPONENT_HEIGHT === 305) {
      setTimeLineComponentHeight(240);
    }
  }, [audioWaveform])

  const isArrowsProTipShown = Boolean(localStorage.getItem('arrowsProTipShown'));

  // console.log('progress', progress);
  const handleKeyDown = (event: KeyboardEvent): void => {
    // console.log('progressRef.current', progressRef.current);
    if (event.key === 'ArrowRight') {
      setCurrentTime((prevValue) => {
        const result = Math.min(prevValue + 0.01, durationRef.current);
        onSeek(result);
        return result
      });
    } else if (event.key === 'ArrowLeft') {
      setCurrentTime((prevValue) => {
        const result = Math.max(prevValue - 0.01, 0);
        onSeek(result);
        return result
      });
    }
  };

  useEffect(() => {
    durationRef.current = duration;
  }, [duration]);

  useEffect(() => {
    if (!isArrowsProTipShown) {
      setShowArrowsProTip(true);
    }
  }, [])

  /* Arrow time control */
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown as EventListener);

    return () => {
      window.removeEventListener('keydown', handleKeyDown as EventListener);
    };
  }, []);

  useEffect(() => {
    setCurrentTime(progress);
  }, [progress]);

  useEffect(() => {
    if (trimValuesProp) {
      setCurrentTrim(trimValuesProp);
    }
  }, [trimValuesProp]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const activeElement = document.activeElement;
      const isInputField =
        activeElement?.tagName === 'INPUT' ||
        activeElement?.tagName === 'TEXTAREA' ||
        (activeElement as HTMLElement)?.isContentEditable;

      if (event.code === 'Space' && isEditorFocused && !isInputField) {
        event.preventDefault(); // Prevent default spacebar action (like scrolling)
        onPlayPause(playing);
      }
    };

    // Add event listener when the component mounts
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [playing, onPlayPause, isEditorFocused]);


  const getSceneByStartTime = (startTime: number) => {
    return allFeedScenes.find(feed => feed.start_time_in_event === startTime);
  };

  const getAllFeedScenesBreakpoints = () => {
    const breakpoints: any[] = [];
    allFeedScenes.forEach(feed => {
      breakpoints.push(feed.start_time_in_event);
    });
    return breakpoints;
  };

  const feedScenesBreakpoints = getAllFeedScenesBreakpoints();

  const styles = useMemo(() => {
    return {
      main: {
        height: TIMELINE_COMPONENT_HEIGHT,
        maxHeight: TIMELINE_COMPONENT_HEIGHT,
        display: 'flex',
        flexDirection: 'column',
        marginTop: 8,
        cursor: 'default',
        position: 'relative'
      },
      playerPlays: {
        display: 'flex',
        gap: '25px',
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        alignItems: 'center'
      },
      playerTime: {
        fontSize: 14,
        lineHeight: '20px',
        color: '#B2ACAC'
      },
      rewindRight: {
        transform: 'rotate(180deg)'
      },
      playPause: {
        display: 'flex',
        alignItems: 'center'
      },
      playerTimelineSection: {
        display: 'flex',
        minHeight: 17,
        backgroundColor: '#17171E',
        padding: '3px 1px',
        justifyContent: 'space-between',
        color: '#A0A0A0',
        fontSize: 10,
        userSelect: 'none'
      },
      timelineDivider: {
        height: 5,
        width: 1,
        backgroundColor: '#797676'
      },
      timeLineDivierWithNumber: {
        display: 'flex',
        gap: 3
      },
      timelineDivierBig: {
        height: 8,
        width: 1,
        backgroundColor: '#797676'
      },
      timelineNumber: {
        fontSize: 10,
        color: '#A0A0A0',
        lineHeight: '20px'
      },
      featureSliders: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        marginTop: '4px',
        maxHeight: '194px',
        overflowY: 'auto',
        height: '111px',
        paddingLeft: '34px',
        paddingRight: '10px',
        width: 'calc(100% + 36px)',
        marginLeft: '-30px',
      }
    } as const;
  }, []);

  const disableVideoSplit = progress === 0 || progress === duration;

  const handleSplit = () => {
    if (!disableVideoSplit) {
      handleSplitVideo && handleSplitVideo(progress);
    }
  };

  const handleSeekForward = () => {
    onSeek(currentTrim[1] - 0.1);
  };

  const handleSeekBack = () => {
    onSeek(currentTrim[0]);
  };

  const handleSeekForwardFiveSeconds = () => {
    onSeek(progress + 5);
  };

  const handleSeekBackFiveSeconds = () => {
    if (progress >= 5) {
      onSeek(progress - 5);
    }
  };

  const handleMainSliderChange = (newTime: number[] | number) => {
    if (Array.isArray(newTime)) {
      setCurrentTime(newTime[0]);
    }
  };
  const handleMainSliderAfterChange = (newTime: number[] | number) => {
    if (Array.isArray(newTime)) {
      onSeek(newTime[0]);
    }
  };
  const handlTrimAfterChange = (value: any) => {
    handleTrimmingBarChange(value);
  };

  const handleTrimChange = (value: any) => {
    setCurrentTrim(value);
    // handleTrimmingBarChange(value); // Temporarily disables optimization
  };

  const calculateSliderHandleHeight = () => {
    let defaultHeight = 72;
    if (feedScenesBreakpoints && feedScenesBreakpoints?.length > 1) {
      defaultHeight += 24;
    }
    if (Array.isArray(audioWaveform)) {
      defaultHeight += 34;
    }
    if (featureSliders.length === 0) {
      return defaultHeight;
    }
    const calculatedHeight = defaultHeight + 3 + 26 * featureSliders.length;
    if (calculatedHeight > 174) return 174; // Max height with scroll bar
    return calculatedHeight;
  };

  const calculateFeedPillWidth = (startTime: number, endTime: number) => {
    const feedDuration = endTime !== undefined ? endTime - startTime : duration - startTime;
    const width = feedDuration * 100 / duration;
    return `${width}%`;
  };

  const calculateFadeOverlayWidth = (position: string) => {
    if (position === 'start') {
      const secondsWidth = currentTrim[0];
      const percentage = secondsWidth * 100 / duration;
      return `${percentage}%`;
    } else if (position === 'middle') {
      const secondsWidth = currentTrim[1] - currentTrim[0];
      const percentage = secondsWidth * 100 / duration;
      return `${percentage}%`;
    } else if (position === 'end') {
      const secondsWidth = duration - currentTrim[1];
      const percentage = secondsWidth * 100 / duration;
      return `${percentage}%`;
    }
    return 0;
  };


  const checkSceneIsHidden = (startTime: number) => {
    const currentScene = getSceneByStartTime(startTime);
    return currentScene?.hidden_scene;
  };

  const getPillClassname = (startTime: number, endTime: number, progress: number) => {
    const endOfFeed = endTime || duration;
    let classNames = '';

    if (progress >= startTime && progress < endOfFeed && !checkSceneIsHidden(startTime)) {
      classNames += ' active';
    }

    if (checkSceneIsHidden(startTime)) {
      classNames += ' hidden-scene';
    }

    if (hoverOnDelete && startTime === currentFeedScene.start_time_in_event) {
      classNames += ' delete-scene-effect';
    }

    return classNames;
  };

  const handleDeleteScene = (breakpointTime: number) => {
    handleDeleteFeedScene && handleDeleteFeedScene(breakpointTime);
  };

  const handleDeleteCurrentScene = () => {
    if (allFeedScenes.length >= 2) {
      handleDeleteScene(currentFeedScene.start_time_in_event);
    } else {
      messageApi.info('Create at least two scenes', 5);
    }
  };

  const handleHideScene = (breakpointTime: number) => {
    handleHideFeedScene && handleHideFeedScene(breakpointTime);
  };

  const handleRevertScene = (breakpointTime: number) => {
    handleRevertFeedScene && handleRevertFeedScene(breakpointTime);
  };

  const handleOnMouseEnterDeleteIcon = () => {
    setHoverOnDelete(true);
  };

  const handleOnMouseLeaveDeleteIcon = () => {
    setHoverOnDelete(false);
  };

  const calculateBufferBarWidth = () => {
    const progress = ((playerLoadedSeconds / duration) * 100) || 0;
    const widthValue = progress >= 100 ? '100%' : progress + '%';
    return widthValue;
  };

  const getVideoDurationWithoutCutScenes = () => {
    let calculatedDuration = duration;
    allFeedScenes.forEach(feed => {
      if (feed.hidden_scene) {
        calculatedDuration -= feed.end_time_in_event - feed.start_time_in_event;
      }
    })
    return formatSeconds(calculatedDuration);
  };

  const getVideoProgressWithoutCutScenes = () => {
    let calculatedProgress = progress;
    allFeedScenes.forEach(feed => {
      if (feed.hidden_scene) {
        if (progress > feed.start_time_in_event) {
          calculatedProgress -= feed.end_time_in_event - feed.start_time_in_event;
        }
      }
    })
    return formatSeconds(calculatedProgress);
  };

  const getTrimmedVideoDurationWithoutCutScenes = () => {
    let calculatedDuration = currentTrim[1] - currentTrim[0];
    allFeedScenes.forEach(feed => {
      if (feed.hidden_scene && (feed.start_time_in_event >= currentTrim[0] && feed.start_time_in_event < currentTrim[1])) {
        calculatedDuration -= feed.end_time_in_event - feed.start_time_in_event;
      }
    })
    return formatSeconds(calculatedDuration);
  };

  const getTrimmedVideoProgressWithoutCutScenes = () => {
    let calculatedProgress = progress - currentTrim[0];
    allFeedScenes.forEach(feed => {
      if (feed.hidden_scene) {
        if (progress > feed.start_time_in_event && (feed.start_time_in_event >= currentTrim[0] && feed.start_time_in_event < currentTrim[1])) {
          calculatedProgress -= feed.end_time_in_event - feed.start_time_in_event;
        }
      }
    })
    return formatSeconds(calculatedProgress);
  };

  const getTooltipProgress = () => {
    let calculatedProgress = currentTime - currentTrim[0];
    allFeedScenes.forEach(feed => {
      if (feed.hidden_scene) {
        if (progress > feed.start_time_in_event && (feed.start_time_in_event >= currentTrim[0] && feed.start_time_in_event < currentTrim[1])) {
          calculatedProgress -= feed.end_time_in_event - feed.start_time_in_event;
        }
      }
    })
    return calculatedProgress > 0 ? calculatedProgress : 0;
  };

  const clickOnDeleteAllScenes = () => {
    setShowDeleteAllScenesModal(true);
  }

  const handleConfirmDeleteAllScenes = () => {
    handleCloseDeleteAllScenesModal();
    handleDeleteAllScenes && handleDeleteAllScenes();
  }

  const handleCloseDeleteAllScenesModal = () => {
    setShowDeleteAllScenesModal(false);
  }

  const handleCloseArrowsProTip = () => {
    setShowArrowsProTip(false);
    localStorage.setItem('arrowsProTipShown', 'true');
  };

  const tipFormatter = (value: number) => {
    return `${formatSecondsWithMilliseconds(parseFloat(value?.toFixed(2)))}`;
  };

  return (
    <div className='video-timeline' style={styles.main}>
      <Modal
        isOpen={showDeleteAllScenesModal}
        className="Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleCloseDeleteAllScenesModal}
      >
        <div style={{ width: 430 }} className="glass-modal">
          <span onClick={handleCloseDeleteAllScenesModal} className="icon-close">X</span>
          <h1>Delete all scenes?</h1>
          <p>Are you sure you would like to delete all scenes?</p>

          <div className="buttons">
            <div onClick={handleCloseDeleteAllScenesModal} style={{ minWidth: 120 }} className="cancel-btn">Leave them</div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }} onClick={handleConfirmDeleteAllScenes} className="red-btn">
              <span>Yes, I&apos;m sure</span>
            </div>
          </div>

        </div>
      </Modal>
      {contextHolder}
      <div style={{ minHeight: 117 }}>
        <div className='player-top-controls'>
          {!disableScenes && <div className='player-top-controls__icons'>
            <Tooltip overlayClassName='tooltip' title='Split video' placement='top'><ScissorsIcon onClick={handleSplit} /></Tooltip>
            <Tooltip overlayClassName='tooltip' title='Merge Scenes' placement='top'><MergeIcon onMouseEnter={handleOnMouseEnterDeleteIcon} onMouseLeave={handleOnMouseLeaveDeleteIcon} className='player-top-controls__delete-icon' onClick={handleDeleteCurrentScene} /></Tooltip>
          </div>
          }
          <div />
          <div className='player-control-top-buttons' style={styles.playerPlays}>
            <RewindIcon onClick={handleSeekBack} />
            <FiveSecondBackIcon onClick={handleSeekBackFiveSeconds} />
            <div style={styles.playPause} onClick={() => onPlayPause(playing)}>{playing ? <PauseIcon /> : <PlayIcon />}</div>
            <FiveSecondForwardIcon onClick={handleSeekForwardFiveSeconds} />
            <RewindIcon style={styles.rewindRight} onClick={handleSeekForward} />
          </div>
          <div style={styles.playerTime}><span style={{ color: COLORS.WHITE }}>{getTrimmedVideoProgressWithoutCutScenes()}</span> / {getTrimmedVideoDurationWithoutCutScenes()}</div>
        </div>
        <div style={styles.playerTimelineSection}>
          {Array.from({ length: TIMELINE_SECTIONS_NUMBER + 1 }).map((_, i) => {
            const secondsNumber = Math.round(duration / TIMELINE_SECTIONS_NUMBER * i);
            return (
              <div key={i}>{i % 2 !== 0 ? <div style={styles.timeLineDivierWithNumber}><span style={styles.timelineDivierBig} /><span style={styles.timelineNumber}>{secondsNumber}</span></div> : <div style={styles.timelineDivider} />}</div>
            );
          })}
        </div>
        <CustomTooltipSlider
          className='timeline-slider'
          range
          step={0.01}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          handleStyle={{ '--after-height': calculateSliderHandleHeight() + 'px' }}
          min={0}
          value={currentTime}
          max={duration}
          onChange={handleMainSliderChange}
          onAfterChange={handleMainSliderAfterChange}
          currentProgress={getTooltipProgress()}
          playing={playing}
        />
        {/* <Slider onChange={value => console.log(value)} step={0.01} min={0} max={104.22} /> */}

        <div style={{ position: 'relative' }}>
          {calculateBufferBarWidth() !== '100%' && <div style={{ width: calculateBufferBarWidth() }} className='buffer-progress-bar'></div>}
          <TooltipSlider
            className='editor-trim-slider'
            style={{ marginBottom: 5 }}
            range
            trackStyle={[{ backgroundColor: 'var(--bg-brand-primary-extra-light)' }]}
            step={0.1}
            min={0}
            max={duration || 30}
            tipFormatter={tipFormatter}
            value={currentTrim}
            onAfterChange={handlTrimAfterChange}
            onChange={handleTrimChange}
            keyboard={false}
            draggableTrack
          />
        </div>
      </div>
      {Array.isArray(audioWaveform) &&
        <div style={{ minHeight: 65, display: 'flex', alignItems: 'end' }}>
          <SoundWave data={audioWaveform} colorStart='#A08BFF' colorEnd='#D9D4FF' />
        </div>
      }

      {feedScenesBreakpoints && feedScenesBreakpoints?.length > 1 &&
        <div style={{ position: 'relative' }}>
          <div className="scenes-delete-icon"><BinIcon onClick={clickOnDeleteAllScenes} /></div>
          <div className="scene-breakpoints">

            {feedScenesBreakpoints.sort((a, b) => a - b).map((breakpointTime, index) => (
              <Tooltip overlayClassName='tooltip' key={breakpointTime + index} title={checkSceneIsHidden(breakpointTime) ? 'Add scene back' : 'Cut scene from video'}>
                <div onClick={() => checkSceneIsHidden(breakpointTime) ? handleRevertScene(breakpointTime) : handleHideScene(breakpointTime)} style={{ width: calculateFeedPillWidth(breakpointTime, feedScenesBreakpoints[index + 1]) }} className={`scene-breakpoints__pill ${getPillClassname(breakpointTime, feedScenesBreakpoints[index + 1], progress)}`}>
                  {checkSceneIsHidden(breakpointTime) ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                  {hoverOnDelete && breakpointTime === currentFeedScene.start_time_in_event && <MergeIcon className='merge-icon' />}
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
      }
      <div className='fade-overlay'>
        <div style={{ width: calculateFadeOverlayWidth('start') }} className="fade-overlay__start"></div>
        <div style={{ width: calculateFadeOverlayWidth('middle') }} className="fade-overlay__middle"></div>
        <div style={{ width: calculateFadeOverlayWidth('end') }} className="fade-overlay__end"></div>
      </div>
      {duration &&
        <>
          <div style={styles.featureSliders}>
            {featureSliders.map(slider => (
              <EditorFeatureSlider
                key={slider.id}
                id={slider.id}
                duration={duration}
                featureName={slider.featureName}
                lockedSlider={slider.type === 'animation'}
                color='#16E88C'
                // color={slider.color}
                value={slider.type === 'animation' ? [0, duration] : slider.startEndTime} // Full width if locked slider
                setFeatureSliders={setFeatureSliders}
                handleDeleteFeatureSlider={handleDeleteFeatureSlider}
                handleSliderTimeChange={handleSliderTimeChange}
              />
            ))}
          </div>
        </>
      }
      <Toast onClose={handleCloseArrowsProTip} isOpen={showArrowsProTip}>
        <div>For precised video frame control you can use your keyboard arrows</div>
        <div className='toast__arrow-icons'>
          <IconArrowLeft />
          <IconArrowLeft />
        </div>
      </Toast>
    </div>
  );
});

VideoTimeline.displayName = 'VideoTimeline';

export default VideoTimeline;


const HandleTooltip = (props: any) => {
  const { value, children, visible, currentProgress, playing, ...restProps } = props;
  const [isVisible, setIsVisible] = useState(visible);
  const timerRef = useRef<number | null>(null);

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
      setIsVisible(true);

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = window.setTimeout(() => setIsVisible(false), 2000);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown as EventListener);

    return () => {
      window.removeEventListener('keydown', handleKeyDown as EventListener);

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  const tipFormatter = (value: number) => {
    return `${formatSecondsWithMilliseconds(parseFloat(currentProgress.toFixed(2)))}`;
  };

  return (
    <Tooltip
      placement="top"
      overlay={tipFormatter(value)}
      overlayInnerStyle={{ minHeight: 'auto' }}
      overlayClassName='tooltip'
      visible={isVisible}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
};

interface CustomTooltipSliderProps extends SliderProps {
  tipFormatter?: (value: number) => React.ReactNode;
  tipProps?: any;
  currentProgress?: number;
  playing?: boolean;
}

const CustomTooltipSlider: React.FC<CustomTooltipSliderProps> = ({ tipFormatter, tipProps, playing, currentProgress, ...props }) => {
  const tipHandleRender: SliderProps['handleRender'] = (node, handleProps) => (
    <HandleTooltip
      value={handleProps.value}
      visible={handleProps.dragging}
      playing={playing}
      tipFormatter={tipFormatter}
      currentProgress={currentProgress}
      {...tipProps}
    >
      {node}
    </HandleTooltip>
  );

  return <Slider {...props} handleRender={tipHandleRender} />;
};