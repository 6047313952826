import { Base64 } from 'js-base64';
import pako from 'pako';
import { useEffect, useRef } from 'react';
import { audioFileTypes } from './constants/content-constants';
import { IStreamer } from './data/intefaces/streamer.interface';
import { IStream } from './data/intefaces/stream.interface';

export const shuffleArray = (array: any[]) => {
  // Create a copy of the array to avoid modifying the original array
  const arr = array.slice();

  // Traverse the array from the last element to the first element
  for (let i = arr.length - 1; i > 0; i--) {
    // Generate a random index between 0 and i (inclusive)
    const j = Math.floor(Math.random() * (i + 1));

    // Swap the current element with the element at the random index
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }

  return arr;
}

export const shuffleArrayWithOtherLast = (array: any[]) => {
  const otherOption = array.find(item => item.value === 'Other'); // Find the "other" option
  const filteredArray = array.filter(item => item.value !== 'Other'); // Filter out the "other" option

  // Shuffle the filtered array
  for (let i = filteredArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [filteredArray[i], filteredArray[j]] = [filteredArray[j], filteredArray[i]];
  }

  // Add the "other" option back to the end
  if (otherOption) {
    filteredArray.push(otherOption);
  }

  return filteredArray;
}

/* DATES */
export const isDateBeforeToday = (timestamp: number) => {
  // Convert the timestamp to a Date object
  const dateFromTimestamp = new Date(timestamp);

  // Get the current date
  const currentDate = new Date();

  // Extract day, month, and year from the Date objects
  const timestampDay = dateFromTimestamp.getDate();

  const currentDay = currentDate.getDate();

  if (timestampDay <= currentDay) return true;

  return false;
};


export const changeMonthToCurrent = (timestamp: number) => {
  // Convert the timestamp to a Date object
  const dateFromTimestamp = new Date(timestamp);

  // Get the current date
  const currentDate = new Date();

  // Get the current month and year
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  // Set the month of the Date object to the current month
  dateFromTimestamp.setMonth(currentMonth);
  dateFromTimestamp.setFullYear(currentYear);

  return dateFromTimestamp.getTime();
};


export const changeMonthToFollowing = (timestamp: number) => {
  // Convert the timestamp to a Date object
  const dateFromTimestamp = new Date(timestamp);
  const currentDate = new Date();

  // Get the current month and year
  const currentMonth = currentDate.getMonth();
  const currentYear = dateFromTimestamp.getFullYear();

  // Increment the month to get the following month
  let followingMonth = currentMonth + 1;
  let followingYear = currentYear;

  // Handle the case where the following month is January of the next year
  if (followingMonth === 12) {
    followingMonth = 0; // January is represented by 0 in JavaScript Date object
    followingYear++;
  }

  // Update the Date object's month and year
  dateFromTimestamp.setMonth(followingMonth);
  dateFromTimestamp.setFullYear(followingYear);

  return dateFromTimestamp.getTime();
};

export const changeYearToFollowing = (timestamp: number) => {
  // Convert the timestamp to a Date object
  const dateFromTimestamp = new Date(timestamp);
  const currentDate = new Date();

  // Get the current month and year
  const currentMonth = currentDate.getMonth();
  const currentYear = dateFromTimestamp.getFullYear();

  // Increment the month to get the following month
  let followingMonth = currentMonth;
  let followingYear = currentYear + 1;

  // Handle the case where the following month is January of the next year
  if (followingMonth === 12) {
    followingMonth = 0; // January is represented by 0 in JavaScript Date object
    followingYear++;
  }

  // Update the Date object's month and year
  dateFromTimestamp.setMonth(followingMonth);
  dateFromTimestamp.setFullYear(followingYear);

  return dateFromTimestamp.getTime();
};


export const addDateEnding = (day: number) => {
  if (day >= 11 && day <= 13) {
    return day + 'th';
  }
  switch (day % 10) {
    case 1:
      return day + 'st';
    case 2:
      return day + 'nd';
    case 3:
      return day + 'rd';
    default:
      return day + 'th';
  }
};


export const getFirstDayOfNextMonth = () => {
  const today = new Date();
  const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
  const day = nextMonth.getDate();
  const month = nextMonth.toLocaleString('default', { month: 'long' });
  const dayWithEnding = addDateEnding(day);
  return `${month} ${dayWithEnding}`;
};


export const getTimeRangeBetweenCurrentAndGivenDate = (timestamp: number) => {
  if (!timestamp) return 0;

  // Get the current time in milliseconds
  const currentTimestamp = Date.now();

  // Calculate the time difference in milliseconds
  const timeDifference = currentTimestamp - timestamp;

  // Convert the time difference to seconds
  const timeDifferenceInSeconds = Math.floor(timeDifference / 1000);

  return timeDifferenceInSeconds; // Output: Time difference in seconds
};


/* SUBSCRIPTIONS */
export const getSubscriptionName = (plan: any) => {
  if (!plan) return 'Free plan';
  if (plan) {
    if (plan.name === 'Spikes Pro') return 'Part Time Creator';
    if (plan.name === 'Spikes Pro+') return 'Full Time Creator';
  }
  return plan?.name;
};

export const getShortSubscriptionName = (planName?: string) => {
  if (!planName) return 'Free';
  if (planName) {
    if (planName === 'Spikes Pro') return 'Spikes Pro';
    if (planName === 'Spikes Pro+') return 'Spikes Pro +';
  }
  return planName;
};

export const calculateProgressBar = (
  progress: number,
  total: number,
  setProgress: (progress: number) => void
) => {
  const interval = setInterval(() => {
    if (progress < 100) {
      const percent = Math.ceil(progress + (100 / total));
      if (percent < 100) {
        setProgress(percent);
      } else {
        setProgress(99);
      }
    }
  }, 1000);
  return () => {
    clearInterval(interval);
  };
};

export const isYoutubeUrl = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
  const liveStreamRegExp = /^.*youtu\.be\/live\/|.*\/live\//;
  return (regExp.test(url) || liveStreamRegExp.test(url)) && url.includes('youtu');
};

export const isTwitchUrl = (url: string) => {
  const regExp = /^(https?:\/\/)?(www\.)?(clips\.)?twitch\.tv\/.*/;
  const isTwitch = regExp.test(url);

  if (isTwitch) {
    if (url.includes('clip')) return true;
    else return false;
  } else {
    return false;
  }
};

export const isVimeoUrl = (url: string) => {
  const regExp = /https?:\/\/(?:www\.)?vimeo\.com\/(?:channels\/[a-zA-Z0-9]+\/)?([0-9]+)/;
  return regExp.test(url);
};

export const isGoogleDriveUrl = (url: string) => {
  if (url.includes('sharing')) {
    const regExp = /https?:\/\/drive\.google\.com\/file\/[^\s/]+/;
    return regExp.test(url);
  } else {
    return false;
  }
};

export const makeFontSizeResponsive = (size: number | string, playerWidth?: number, isVerticalVideo?: boolean, returnNumber?: boolean) => {
  if (!playerWidth) return size;

  if (isVerticalVideo) {
    const verticalVideoHeight = playerWidth * 1.777777777777778; // 16:9 aspect ratio
    const theoreticalDesktopPlayerWidth = verticalVideoHeight * 1.777777777777778; // What would be desktop player width for this height
    playerWidth = theoreticalDesktopPlayerWidth;
  }

  const COEFFICIENT_20 = 55.31033388;
  const COEFFICIENT_28 = 49.38422668;
  const COEFFICIENT_32 = 43.21119835;
  const COEFFICIENT_35 = 39.50738135;

  let finalCoefficient = COEFFICIENT_32;

  if (size === 20 || size === 'Small') finalCoefficient = COEFFICIENT_20;
  else if (size === 28 || size === 'Medium') finalCoefficient = COEFFICIENT_28;
  else if (size === 32 || size === 'Big') finalCoefficient = COEFFICIENT_32;
  else if (size === 35 || size === 'Huge') finalCoefficient = COEFFICIENT_35;

  const result = playerWidth / finalCoefficient;

  if (returnNumber) return result;
  else return `${result}px`;
};

export const roundWithTwoDecimals = (num: number | null) => {
  if (!num) return num;
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const roundWithOneDecimal = (num: number | null) => {
  if (!num) return num;
  return Math.round((num + Number.EPSILON) * 10) / 10;
};

export const showGoProButton = (user: any) => {
  if (!user) return false;
  if (user?.permissions && user.permissions.hd_enabled) return false;
  return true;
};

export const getPricingRedirectUrl = (user: any) => {
  if (showGoProButton(user)) {
    return '/subscriptions';
  } else {
    return '/dashboard';
  }
}


export const roundUpToNearestMultipleOfThree = (numberSeconds: number) => {
  let minutes = Math.floor(numberSeconds / 60);
  if (minutes === 0) minutes = 1;
  return Math.ceil(minutes / 3) * 3;
};

// Format time in mm:ss.SSS format
export const formatTime = (time: number): string => {
  const minutes = Math.floor(time / 60000);
  const seconds = Math.floor((time % 60000) / 1000);
  const milliseconds = Math.floor(time % 1000);

  const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}.${milliseconds.toString().padStart(3, '0')}`;
  return formattedTime;
};

// Reformats time from  mm:ss.SSS format to an initial
export const reformatTime = (formattedTime: string): number => {
  const [minutesStr, secondsStr, millisecondsStr] = formattedTime.split(/[:.]/);

  const minutes = parseInt(minutesStr, 10) || 0;
  const seconds = parseInt(secondsStr, 10) || 0;
  const milliseconds = parseInt(millisecondsStr, 10) || 0;

  const totalTimeInMilliseconds = minutes * 60000 + seconds * 1000 + milliseconds;

  return totalTimeInMilliseconds;
};


export async function decompressData(encodedData: any) {
  // First, decode the URL-encoded data
  const base64Decoded = decodeURIComponent(encodedData);
  // Then, decode the Base64-encoded data
  const compressedData = Uint8Array.from(atob(base64Decoded), c => c.charCodeAt(0));
  // Use pako to decompress the data
  const decompressedData = pako.inflate(compressedData, { to: 'string' });
  // Convert the decompressed data back to a JSON object
  const assSubtitles = JSON.parse(decompressedData);
  return assSubtitles;
}

export async function compressData(assSubtitles: any) {
  const assSubtitlesJson = JSON.stringify(assSubtitles);
  const compressedBuffer = await pako.deflate(assSubtitlesJson, { level: 6 });
  const base64Encoded = await bufferToBase64(compressedBuffer);
  const urlEncoded = encodeURIComponent(base64Encoded);
  return urlEncoded;
}

export async function bufferToBase64(buffer: Uint8Array) {
  // use a FileReader to generate a base64 data URI:
  const base64url: any = await new Promise(r => {
    const reader = new FileReader();
    reader.onload = () => r(reader.result);
    reader.readAsDataURL(new Blob([buffer]));
  });
  // remove the `data:...;base64,` part from the start
  return base64url.slice(base64url.indexOf(',') + 1);
}

// Function to detect why component was re-rendered (helps to indentify which props are being changed and causing re-render)
export function useTraceUpdate(props: any) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps: any, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps);
    }
    prev.current = props;
  });
}

export const completeOnboardingStep = (step: string, user: IStreamer | null | undefined) => {
  if (user && user?.onboarding_complete) {
    return null;
  }
  const onboardingSteps = localStorage.getItem('onboarding_popup_steps');

  if (onboardingSteps) {
    const parsedSteps = JSON.parse(onboardingSteps);
    parsedSteps[step] = true;
    localStorage.setItem('onboarding_popup_steps', JSON.stringify(parsedSteps));
  }
}

export const checkIfOnboardingStepCompleted = (step: string) => {
  const onboardingSteps = localStorage.getItem('onboarding_popup_steps');

  if (onboardingSteps) {
    const parsedSteps = JSON.parse(onboardingSteps);
    return Boolean(parsedSteps[step]);
  }
}

export const checkUserIsIndian = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (timeZone === 'Asia/Kolkata' || timeZone === 'Asia/Calcutta') {
    return true;
  } else return false;
};


export function convertToISO(dateObj: { year: number, month: number, day: number, hour: number, minute: number }): string {
  const { year, month, day, hour, minute } = dateObj;

  // Create a new Date object using the provided values, assuming they are in local time.
  const localDate = new Date(year, month - 1, day, hour, minute);

  // Convert to ISO 8601 string and return, ensuring it represents the correct UTC time.
  return localDate.toISOString();
}


export const renderScheduleDate = (date: any) => {
  const dateString = `${date.day}/${date.month}/${date.year} ${renderHourOrMinute(date.hour)}:${renderHourOrMinute(date.minute)}`;
  return dateString;
};

const renderHourOrMinute = (value: number) => {
  if (value < 10) {
    return `0${value}`;
  } else {
    return value;
  }
};

export function isISOAfterCurrentDate(providedDateStr: string): boolean {
  // Parse the provided ISO 8601 date string to a Date object
  const providedDate = new Date(providedDateStr);

  // Get the current time in UTC as a Date object
  const currentDate = new Date();

  // Compare the two Date objects
  if (providedDate < currentDate) {
    return false
  } else if (providedDate > currentDate) {
    return true;
  } else {
    return false;
  }
}

export function formatISODateToShortReadable(isoDateStr: string): string {
  if (!isoDateStr) return '';
  // Parse the provided ISO 8601 date string to a Date object
  const date = new Date(isoDateStr);

  // Get the month, day, and year from the date object
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  const year = date.getUTCFullYear();

  // Get the hours, minutes, and seconds from the date object
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');

  // Format the date into a short readable string
  const readableDate = `${month}/${day}/${year} ${hours}:${minutes}:${seconds} UTC`;

  return readableDate;
}

export function secondsToMinutesAndSeconds(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  if (minutes === 0) {
    return `${remainingSeconds}s`;
  } else if (remainingSeconds === 0) {
    return `${minutes}m`;
  } else {
    return `${minutes}m ${remainingSeconds}s`;
  }
};

export const isNumber = (value: any) => {
  if (typeof value === 'number') return true;
  else return false;
};

export const checkFileType = (fileExtension: string): 'audio' | 'video' => {
  if (!fileExtension) return 'video';
  if (audioFileTypes.indexOf(fileExtension) !== -1) return 'audio';
  return 'video';
};

export const removeFileExtension = (filename: string) => {
  return filename.split('.').slice(0, -1).join('.');
};

export const getEnabledGifsForUser = (user: IStreamer | null | undefined): boolean => {
  if (user === null || user === undefined) {
    return false;
  }

  if (user?.permissions) {
    return user?.permissions.gifs_enabled;
  }

  if (user?.subscriptions && user?.subscriptions[0]) {
    return true;
  }

  return false;
};

export const getEnabledBrandingForUser = (user: IStreamer | null | undefined): boolean => {
  if (user === null || user === undefined) {
    return false;
  }

  if (user?.permissions) {
    return user?.permissions.gifs_enabled;
  }

  if (user?.subscriptions && user?.subscriptions[0]) {
    return true;
  }

  return false;
};


export const getEnabledElementsForUser = (user: IStreamer | null | undefined): boolean => {
  if (user === null || user === undefined) {
    return true;
  }

  if (user?.permissions) {
    return user?.permissions.elements_enabled;
  }

  if (user?.subscriptions && user?.subscriptions[0]) {
    return true;
  }

  return true;
};


export const getEnabledAnimatedCaptionsForUser = (user: IStreamer | null | undefined): boolean => {
  if (user === null || user === undefined) {
    return false;
  }

  if (user?.permissions) {
    return user?.permissions.animated_captions[0] === 'ALL';
  }

  if (user?.subscriptions && user?.subscriptions[0]) {
    return true;
  }

  return false;
};

// 1 340 => 1.34K
export const formatViews = (views: any) => {
  if (views < 1000) {
    return views.toString();
  } else if (views < 1000000) {
    return (views / 1000).toFixed(1) + 'K';
  } else {
    return (views / 1000000).toFixed(2) + 'M';
  }
};

export function getDifferenceInHours(timestamp1: number, timestamp2: number): number {
  const millisecondsPerHour = 1000 * 60 * 60; // Number of milliseconds in one hour
  const differenceInMilliseconds = Math.abs(timestamp1 - timestamp2); // Absolute difference in milliseconds
  return differenceInMilliseconds / millisecondsPerHour;
}

export const convertToBoolean = (value: any) => {
  if (value === "true") {
    return true;
  } else if (value === "false") {
    return false;
  } else {
    return value;
  }
}

export const checkStreamIsAvailableToRender = (stream: IStream) => {
  if (!stream?.clips || stream?.clips.length === 0) return false;
  if (stream?.clips.length > 0) {
    let isReady = false;
    stream?.clips.forEach(clip => {
      if (clip.is_manual_edit === false && clip.status !== 'in progress') {
        isReady = true;
      }
      if (clip.is_manual_edit) {
        isReady = true;
      }
    })
    return isReady;
  }
  return true;
}

export const getOS = () => {
  const userAgent = window.navigator.userAgent;
  if (userAgent.indexOf('Mac') !== -1) {
    return 'MacOS';
  } else if (userAgent.indexOf('Windows') !== -1) {
    return 'Windows';
  } else {
    return 'Other';
  }
};

export function getTimeUntilExpiration(expirationDate: number): { value: string, lessThanOneDay: boolean } {
  const currentTime = Date.now();
  const timeDifference = expirationDate - currentTime;

  if (timeDifference <= 0) {
    return {
      value: 'Expired',
      lessThanOneDay: true
    };
  }

  const millisecondsInADay = 24 * 60 * 60 * 1000;
  const millisecondsInAnHour = 60 * 60 * 1000;

  if (timeDifference >= millisecondsInADay) {
    const daysUntilExpiration = Math.floor(timeDifference / millisecondsInADay);
    return {
      value: `${daysUntilExpiration} ${daysUntilExpiration === 1 ? 'day' : 'days'}`,
      lessThanOneDay: false
    };
  } else {
    let hoursUntilExpiration: string | number = Math.floor(timeDifference / millisecondsInAnHour);
    if (hoursUntilExpiration === 0) {
      hoursUntilExpiration = 'less than 1';
    }
    return {
      value: `${hoursUntilExpiration} ${(hoursUntilExpiration === 1 || typeof hoursUntilExpiration === 'string') ? 'hour' : 'hours'}`,
      lessThanOneDay: true
    };
  }
}

export function truncateText(text: string, limit: number): string {
  if (text.length <= limit) {
    return text;
  }
  return text.slice(0, limit) + '...';
}