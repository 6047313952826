import React, { useEffect, useState, useRef } from 'react';
import SubtitlesWord from '../subtitles-word/SubtitlesWord';
import {
  SubtitleSentence,
  SubtitleWord,
} from '../../../../data/intefaces/stream.interface';
import './SubtitlesPreview.less';
import SubtitlesEmoji from '../subtitles-emoji/SubtitlesEmoji';
import '../../AnimatedCaptions/AnimatedCaptions.less';
import { ANIMATIONS_LIST } from '../../../../constants/content-constants';
import { makeFontSizeResponsive } from '../../../../utils';

interface SubtitlesPreviewProps {
  sentences: SubtitleSentence[];
  rightToLeft?: boolean;
  playerRef: React.RefObject<HTMLVideoElement>;
  updateSentences: (updatedSentences: SubtitleSentence[]) => void;
  componentHeight?: number;
  emojiEnabled?: boolean;
  playerWidth?: number;
  animationSelected?: string;
  isVerticalVideo: boolean;
}

const ALIGN_BOTTOM_RATIO = -6.111111111111111; // Component HEIGHT / RATIO = Bottom offset (e.g 550 / -ratio = -90px)
const ALIGN_MIDDLE_RATIO = -2.391304347826087;
const ALIGN_TOP_RATIO = -1.486486486486486;

const SubtitlesPreview: React.FC<SubtitlesPreviewProps> = ({
  sentences,
  playerRef,
  rightToLeft,
  updateSentences,
  componentHeight,
  emojiEnabled,
  animationSelected,
  playerWidth,
  isVerticalVideo
}) => {
  const videoElement = document.getElementsByTagName('video')[0];
  const [currentTime, setCurrentTime] = useState(0);
  const currentSentenceIndexRef = useRef(0);

  const requestAnimationFrameRef = useRef<number>();

  useEffect(() => {
    // Update the current time continuously using requestAnimationFrame
    const updateCurrentTime = () => {
      if (videoElement) {
        setCurrentTime(videoElement.currentTime);
      }

      requestAnimationFrameRef.current =
        requestAnimationFrame(updateCurrentTime);
    };

    // Start updating the current time
    requestAnimationFrameRef.current = requestAnimationFrame(updateCurrentTime);

    return () => {
      // Clean up by canceling the requestAnimationFrame
      if (requestAnimationFrameRef.current) {
        cancelAnimationFrame(requestAnimationFrameRef.current);
      }
    };
  }, [videoElement]);

  const handleWordChange = (
    param: string,
    value: string | number,
    sentenceIndex: number,
    wordIndex: number,
  ) => {
    // Update the words in the current sentence with the modified values
    const updatedSentences = [...sentences];
    const updatedSentence = { ...updatedSentences[sentenceIndex] };
    const updatedWord = { ...updatedSentence.words[wordIndex], [param]: value };

    updatedSentence.words[wordIndex] = updatedWord;
    updatedSentences[sentenceIndex] = updatedSentence;
    updateSentences(updatedSentences); // Trigger a re-render or update the state
  };

  useEffect(() => {
    // Update the current sentence index based on the current time
    const updateCurrentSentenceIndex = () => {
      const sentenceIndex = sentences.findIndex((sentence) => {
        const wordIndex = sentence.words.findIndex(
          (word) =>
            currentTime * 1000 >= word.start - 50 &&
            currentTime * 1000 < word.end,
        );
        return wordIndex !== -1;
      });

      if (sentenceIndex !== -1) {
        currentSentenceIndexRef.current = sentenceIndex;
      } else {
        currentSentenceIndexRef.current = -1; // Set the current sentence index to -1 when no words are found
      }
    };

    updateCurrentSentenceIndex();
  }, [currentTime, sentences]);

  const currentSentenceIndex = currentSentenceIndexRef.current;
  const currentSentence = sentences[currentSentenceIndex];

  const calculateAlignItemsOffset = (align: 'top' | 'middle' | 'bottom') => {
    switch (align) {
      case 'top':
        if (componentHeight) {
          return `${componentHeight / ALIGN_TOP_RATIO}px 0px 0px 0px`;
        } else return '-370px 0px 0px 0px';
      case 'middle':
        if (componentHeight) {
          return `${componentHeight / ALIGN_MIDDLE_RATIO}px 0px 0px 0px`;
        } else return '-230px 0px 0px 0px';
      case 'bottom':
        if (componentHeight) {
          return `${componentHeight / ALIGN_BOTTOM_RATIO}px 0px 0px 0px`;
        } else return '-90px 0px 0px 0px';
    }
  };

  const calculateFontSize = (size: 'huge' | 'big' | 'medium' | 'small') => {
    if (size === 'huge') {
      if (componentHeight && componentHeight < 400) {
        return '24px';
      } else return '35px';
    } else if (size === 'big') {
      if (componentHeight && componentHeight < 400) {
        return '22px';
      } else return '32px';
    } else if (size === 'medium') {
      if (componentHeight && componentHeight < 400) {
        return '20px';
      } else return '28px';
    } else if (size === 'small') {
      if (componentHeight && componentHeight < 400) {
        return '16px';
      } else return '20px';
    } else return '24px';
  };
  const calculateLineHeight = (size: 'huge' | 'big' | 'medium' | 'small') => {
    if (size === 'huge') {
      if (componentHeight && componentHeight < 400) {
        return '26px';
      } else return '46px';
    } else if (size === 'big') {
      if (componentHeight && componentHeight < 400) {
        return '24px';
      } else return '36px';
    } else if (size === 'medium') {
      if (componentHeight && componentHeight < 400) {
        return '22px';
      } else return '30px';
    } else if (size === 'small') {
      if (componentHeight && componentHeight < 400) {
        return '18px';
      } else return '24px';
    } else return '24px';
  };

  const calculatEmojiSize = (size: 'huge' | 'big' | 'medium' | 'small') => {
    if (size === 'huge') {
      if (componentHeight && componentHeight < 400) {
        return '42px';
      } else return '44px';
    } else if (size === 'big') {
      if (componentHeight && componentHeight < 400) {
        return '36px';
      } else return '40px';
    } else if (size === 'medium') {
      if (componentHeight && componentHeight < 400) {
        return '32px';
      } else return '36px';
    } else if (size === 'small') {
      if (componentHeight && componentHeight < 400) {
        return '24px';
      } else return '30px';
    } else return '32px';
  };

  const presets = {
    // Presets for different subtitle styles
    alignItems: {
      Top: calculateAlignItemsOffset('top'),
      Middle: calculateAlignItemsOffset('middle'),
      Bottom: calculateAlignItemsOffset('bottom'),
    },
    fontSize: {
      Huge: calculateFontSize('huge'),
      Big: calculateFontSize('big'),
      Medium: calculateFontSize('medium'),
      Small: calculateFontSize('small'),
    },
    emoji_size: {
      XL: calculatEmojiSize('huge'),
      L: calculatEmojiSize('big'),
      M: calculatEmojiSize('medium'),
      S: calculatEmojiSize('small'),
    },
    emoji_horizontal_orientation: {
      left: 'start',
      center: 'center',
      right: 'end',
    },
    lineHeight: {
      Huge: calculateLineHeight('huge'),
      Big: calculateLineHeight('big'),
      Medium: calculateLineHeight('medium'),
      Small: calculateLineHeight('small'),
    },
    textShadow: {
      Light:
        '0.0432em 0.0432em 0 var(--text-shadow-color), -0.0216em -0.0216em 0 var(--text-shadow-color),-0.0216em 0.0216em 0 var(--text-shadow-color), 0.0216em -0.0216em 0 var(--text-shadow-color), 0.0216em 0.0216em 0 var(--text-shadow-color)',
      Medium:
        '0.0px 2.34375px 0.0046875px var(--text-shadow-color), 2.296875px 0.4921875px 0.0046875px var(--text-shadow-color), 0.984375px -2.1328125px 0.0046875px var(--text-shadow-color), -1.875px -1.40625px 0.0046875px var(--text-shadow-color), -1.78125px 1.5234375px 0.0046875px var(--text-shadow-color), 1.125px 2.0625px 0.0046875px var(--text-shadow-color), 2.25px -0.65625px 0.0046875px var(--text-shadow-color), -0.1640625px -2.34375px 0.0046875px var(--text-shadow-color), -2.3203125px -0.3515625px 0.0046875px var(--text-shadow-color)',
      Heavy:
        '0.0px 5.0px 0.01px var(--text-shadow-color), 4.9px 1.05px 0.01px var(--text-shadow-color), 2.1px -4.55px 0.01px var(--text-shadow-color), -4.0px -3.0px 0.01px var(--text-shadow-color), -3.8px 3.25px 0.01px var(--text-shadow-color), 2.4px 4.4px 0.01px var(--text-shadow-color), 4.8px -1.4px 0.01px var(--text-shadow-color), -0.35px -5.0px 0.01px var(--text-shadow-color), -4.95px -0.75px 0.01px var(--text-shadow-color), -1.75px 4.7px 0.01px var(--text-shadow-color), 4.2px 2.7px 0.01px var(--text-shadow-color), 3.55px -3.5px 0.01px var(--text-shadow-color), -2.7px -4.2px 0.01px var(--text-shadow-color), -4.7px 1.75px 0.01px var(--text-shadow-color), 0.7px 4.95px 0.01px var(--text-shadow-color), 5.0px 0.4px 0.01px var(--text-shadow-color), 1.45px -4.8px 0.01px var(--text-shadow-color), -4.35px -2.4px 0.01px var(--text-shadow-color), -3.3px 3.75px 0.01px var(--text-shadow-color), 2.95px 4.0px 0.01px var(--text-shadow-color), 4.55px -2.05px 0.01px var(--text-shadow-color), -1.05px -4.9px 0.01px var(--text-shadow-color), -5.0px -0.05px 0.01px var(--text-shadow-color), -1.1px 4.9px 0.01px var(--text-shadow-color), 4.55px 2.1px 0.01px var(--text-shadow-color), 3.05px -4.0px 0.01px var(--text-shadow-color), -3.25px -3.8px 0.01px var(--text-shadow-color), -4.4px 2.35px 0.01px var(--text-shadow-color), 1.35px 4.8px 0.01px var(--text-shadow-color), 4.8px -1.0px 0.01px var(--text-shadow-color), 0.1px -5.0px 0.01px var(--text-shadow-color), -4.85px -1.15px 0.01px var(--text-shadow-color), -2.15px 4.5px 0.01px var(--text-shadow-color), 3.95px 3.05px 0.01px var(--text-shadow-color)',
    },
    fontWeight: {
      normal: '500',
      bold: '700',
    },
    textTransform: {
      none: 'none',
      uppercase: 'uppercase',
      capitalize: 'capitalize',
      lowercase: 'lowercase',
    } as const,
  };

  const heightheight = currentSentence?.words[0]?.alignItems;
  const alignValue =
    presets.alignItems[heightheight as keyof typeof presets.alignItems];

  const emojiPositionClassname = (sentence: any) => {
    const wordWithEmoji: SubtitleWord = sentence.words.find(
      (word: SubtitleWord) => word?.emoji !== 'none',
    );
    if (wordWithEmoji) {
      if (wordWithEmoji.emoji_captions_pos === 'above') {
        return 'column-reverse';
      } else return '';
    } else {
      return '';
    }
  };

  const selectedAnimationObject = ANIMATIONS_LIST.find((item) => item.animationName === animationSelected);
  function generateTextShadowForFontSize(fontSize: number, textShadowKey: keyof typeof presets.textShadow) {
    // Define base shadow offsets for a large reference font size (e.g., 100px)
    const referenceFontSize = 100;

    let multiplier = 3;

    if (textShadowKey === 'Light') {
      multiplier = 2;
    } else if (textShadowKey === 'Medium') {
      multiplier = 3;
    } else if (textShadowKey === 'Heavy') {
      multiplier = 4;
    } else if (textShadowKey === 'None') {
      return 'none';
    }

    // Scale factor for larger stroke effect
    const scaleFactor = (fontSize / referenceFontSize) * multiplier; // Multiply by 2 to increase stroke size

    // Original offsets from your text-shadow
    const baseOffsets = [
      [0.0, 5.0],
      [4.9, 1.05],
      [2.1, -4.55],
      [-4.0, -3.0],
      [-3.8, 3.25],
      [2.4, 4.4],
      [4.8, -1.4],
      [-0.35, -5.0],
      [-4.95, -0.75],
      [-1.75, 4.7],
      [4.2, 2.7],
      [3.55, -3.5],
      [-2.7, -4.2],
      [-4.7, 1.75],
      [0.7, 4.95],
      [5.0, 0.4],
      [1.45, -4.8],
      [-4.35, -2.4],
      [-3.3, 3.75],
      [2.95, 4.0],
      [4.55, -2.05],
      [-1.05, -4.9],
      [-5.0, -0.05],
      [-1.1, 4.9],
      [4.55, 2.1],
      [3.05, -4.0],
      [-3.25, -3.8],
      [-4.4, 2.35],
      [1.35, 4.8],
      [4.8, -1.0],
      [0.1, -5.0],
      [-4.85, -1.15],
      [-2.15, 4.5],
      [3.95, 3.05],
    ];

    // Scale the offsets based on the font size and adjust for larger stroke
    const scaledOffsets = baseOffsets.map((offset) => {
      const [x, y] = offset;
      return `${(x * scaleFactor).toFixed(2)}px ${(y * scaleFactor).toFixed(
        2,
      )}px 0.01px var(--text-shadow-color)`;
    });

    // Join all shadows into a single string
    return scaledOffsets.join(', ');
  }

  return (
    <div
      className="subtitles-container-preview"
      style={{ alignItems: alignValue }}
    >
      {currentSentence && currentSentence.words && (
        <>
          {currentSentence.words[0].emoji_captions_pos === 'above'}
          <div className={`subtitles-preview ${emojiPositionClassname(currentSentence)}`}>
            <div style={{
              position: 'relative',
              inset: presets.alignItems[currentSentence.words[0].alignItems as keyof typeof presets.alignItems],
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div
                className={selectedAnimationObject?.boxClassName || ''}
                style={{
                  ...rightToLeft ? { direction: 'rtl' } : {}
                }}>
                {currentSentence.words.map((word, wordIndex) => {
                  const fontSizeKey = word.fontSize as keyof typeof presets.fontSize;
                  const fontWeightKey = word.fontWeight as keyof typeof presets.fontWeight;
                  const textTransformKey = word.textTransform as keyof typeof presets.textTransform;
                  const textShadowKey = word.textShadow as keyof typeof presets.textShadow;
                  const alignItemsKey = word.alignItems as keyof typeof presets.alignItems;
                  const emoji = word.emoji;

                  const isWordInTimeFrame = currentTime * 1000 >= word.start - 50 && currentTime * 1000 < word.end;

                  // Determine the animation value based on whether the word is within the time frame
                  const animation = selectedAnimationObject?.withActiveClass ? animationSelected : (isWordInTimeFrame ? animationSelected : '');

                  return (
                    <SubtitlesWord
                      key={wordIndex}
                      background={word.background}
                      start={word.start}
                      end={word.end}

                      // !!! Dynamic captions font size !!! //
                      fontSize={makeFontSizeResponsive(fontSizeKey, playerWidth, isVerticalVideo) as string}
                      // fontSize={presets.fontSize[fontSizeKey]}

                      // !!! Dynamic captions font size !!! //
                      lineHeight={makeFontSizeResponsive(fontSizeKey, playerWidth, isVerticalVideo, true) as number * 1.3 + 'px'}
                      // lineHeight={presets.lineHeight[fontSizeKey]}

                      fontFamily={word.fontFamily}
                      fontWeight={presets.fontWeight[fontWeightKey]}
                      word={word.word}
                      color={word.color}
                      textTransform={presets.textTransform[textTransformKey]}

                      // !!! Dynamic captions font size !!! //
                      textShadow={generateTextShadowForFontSize(makeFontSizeResponsive(fontSizeKey, playerWidth, isVerticalVideo, true) as number, textShadowKey)}
                      // textShadow={presets.textShadow[textShadowKey]}

                      isHighlighted={word.isHighlighted}
                      isPreview
                      alignItems={presets.alignItems[alignItemsKey]}
                      animation={animation}
                      emoji={emoji}
                      isCurrentWord={isWordInTimeFrame}
                      isSingleWord={currentSentence.words.length === 1}
                      isWordNotYetActive={currentTime * 1000 < word.start - 50}
                    />
                  );
                })}
              </div>
            </div>
            {emojiEnabled && (
              <div>
                {currentSentence.words.map((word, i) => {
                  const alignItemsKey =
                    word.alignItems as keyof typeof presets.alignItems;
                  const emojiSizeKey =
                    word.emoji_size as keyof typeof presets.emoji_size;
                  const horizontalAlignment = word.emoji_horizontal_orientation;
                  const rotationDegrees = word.emoji_rotation_degrees;

                  if (word?.emoji)
                    return (
                      <SubtitlesEmoji
                        emoji={word?.emoji}
                        key={i}
                        fontSize={presets.emoji_size[emojiSizeKey]}
                        alignItems={presets.alignItems[alignItemsKey]}
                        gap={word.emoji_captions_dist}
                        textAlign={horizontalAlignment}
                        rotation={rotationDegrees}
                        position={word?.emoji_captions_pos}
                        isHighlighted={word.isHighlighted}
                        isPreview
                      />
                    );
                })}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SubtitlesPreview;
