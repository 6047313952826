import React, { useContext, useState } from 'react';
import './Sidebar.less';
// import { ReactComponent as IconAiClipGenerator } from '../../assets/ai_clip_generator.svg';
import { ReactComponent as IconPlay } from '../../assets/play_simple.svg';
import { ReactComponent as IconMagicWand } from '../../assets/magic_wand_icon.svg';
import { ReactComponent as IconAddCaptions } from '../../assets/add_captions.svg';
import { ReactComponent as IconSettings } from '../../assets/settings.svg';
import { ReactComponent as IconDashboard } from '../../assets/dashboard_new.svg';
import { ReactComponent as IconLoyalty } from '../../assets/loyalty.svg';
import { ReactComponent as IconAddPeople } from '../../assets/add_people.svg';
import { ReactComponent as IconCrown } from '../../assets/crown_new.svg';
import { ReactComponent as IconInfo } from '../../assets/info-icon.svg';
import { ReactComponent as IconAvatar } from '../../assets/avatar_circle.svg';
import { ReactComponent as IconLogout } from '../../assets/logout.svg';
import { useNavigate } from 'react-router';
import { showGoProButton } from '../../utils';
import { UserContext } from '../../data/userContext';
import { SettingsModal } from '../settings-modal';

const navigationItems = [
  {
    label: 'Create Clips',
    icon: <IconPlay style={{ width: 15, height: 15, paddingLeft: 3 }} />,
    href: '/spikes/videos'
  },
  {
    label: 'Live Streams AI',
    icon: <IconMagicWand />,
    href: '/spikes/streams'
  },
];


const Sidebar = ({ isMobile = false }: { isMobile?: boolean }) => {
  const [isOpen, setIsOpen] = useState(isMobile ? true : true);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const handleOpenSidebar = () => {
    setIsOpen(true);
  };

  const handleCloseSidebar = () => {
    !isMobile && setIsOpen(false);
  };

  const handleClickOnItem = (href?: string) => {
    if (href) {
      navigate(href);
    }
  };

  const checkCurrentItem = (href?: string) => {
    if (href) {
      return location.pathname.includes(href);
    }
    return false;
  };

  const handleSettingsClick = () => {
    setShowSettingsModal(true);
    handleCloseSidebar();
  };

  const handleDashboardClick = () => {
    navigate('/dashboard');
    handleCloseSidebar();
  };

  const handleInviteClick = () => {
    navigate('/affiliate-program');
    handleCloseSidebar();
  };

  const handleLoyaltyClick = () => {
    navigate('/spikes/loyalty-program');
    handleCloseSidebar();
  };

  const handleTutorialsClick = () => {
    navigate('/tutorial');
    handleCloseSidebar();
  };


  const handleLogout = () => {
    localStorage.removeItem('user');
    window.location.href = '/';
    handleCloseSidebar();
  };

  const handleGoPro = () => {
    navigate('/subscriptions');
  };

  const actionItems = [
    // {
    //   label: 'Settings',
    //   icon: <IconSettings />,
    //   onClick: handleSettingsClick
    // },
    {
      label: 'Dashboard',
      icon: <IconDashboard />,
      onClick: handleDashboardClick
    },
    {
      label: 'Loyalty Program',
      icon: <IconLoyalty />,
      onClick: handleLoyaltyClick
    },
    {
      label: 'Invite a Friend',
      icon: <IconAddPeople />,
      onClick: handleInviteClick
    },
    {
      label: 'Tutorials',
      icon: <IconAddPeople />,
      onClick: handleTutorialsClick
    },
  ];

  if (isMobile) actionItems.unshift(
    {
      label: 'Settings',
      icon: <IconSettings />,
      onClick: handleSettingsClick
    },
  )

  return (
    <div onMouseEnter={handleOpenSidebar} onMouseLeave={handleCloseSidebar} className={`sidebar ${isMobile && 'mobile'}`}>
      {navigationItems.map(item => (
        <div onClick={() => handleClickOnItem(item?.href)} key={item.label} className={`sidebar__item ${isOpen ? 'opened' : 'closed'} ${checkCurrentItem(item?.href) ? 'active' : ''}`}>
          {item?.icon && item?.icon}
          <span>{item.label}</span>
        </div>
      ))}

      <div className='sidebar__divider' />

      {showGoProButton(userContext?.user) && (
        <div onClick={handleGoPro} className={`sidebar__item go-pro ${isOpen ? 'opened' : 'closed'}`}>
          <IconCrown />
          <span>Go Pro</span>
        </div>
      )}
      {actionItems.map(item => (
        <div onClick={item?.onClick} key={item.label} className={`sidebar__item ${isOpen ? 'opened' : 'closed'}`}>
          {item?.icon && item?.icon}
          <span>{item.label}</span>
        </div>
      ))}

      <div className='sidebar__divider' />

      <div className={`sidebar__item avatar ${isOpen ? 'opened' : 'closed'}`}>
        <IconAvatar />
        <span>{userContext?.user?.username}</span>
      </div>
      <div onClick={() => navigate('/about')} className={`sidebar__item ${isOpen ? 'opened' : 'closed'}`}>
        <IconInfo />
        <span>About</span>
      </div>
      <div onClick={handleLogout} className={`sidebar__item ${isOpen ? 'opened' : 'closed'}`}>
        <IconLogout />
        <span>Logout</span>
      </div>

      {showSettingsModal && (
        <SettingsModal onClose={() => setShowSettingsModal(false)} />
      )}
    </div>
  )
}

export default Sidebar;