import './DropdownMenu.less';
import React, { useState, useRef, useEffect, ReactElement } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

export interface DropdownItemProps {
  label?: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  to?: string;
  divider?: boolean;
}

interface DropdownMenuProps {
  trigger: React.ReactNode;
  position?: 'bottom' | 'top';
  heading?: string,
  children:
  | ReactElement<DropdownItemProps>
  | ReactElement<DropdownItemProps>[];
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  trigger,
  position = 'bottom',
  children,
  heading
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleItemClick = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      className={classNames('dropdown-menu', position)}
      ref={dropdownRef}
    >
      <div
        className="dropdown-trigger"
        onClick={(e) => {
          e.stopPropagation();
          handleToggle();
        }}
        aria-haspopup="true"
        aria-expanded={isOpen}
        role="button"
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.stopPropagation();
            handleToggle();
          }
        }}
      >
        {trigger}
      </div>
      <CSSTransition
        in={isOpen}
        timeout={200}
        classNames="dropdown-content"
        unmountOnExit
      >
        <div className="dropdown-content right">
          {heading && <div className="dropdown-heading">{heading}</div>}
          {React.Children.map(children, (child) =>
            React.isValidElement<DropdownItemProps>(child)
              ? React.cloneElement(child, {
                onClick: () => {
                  if (child.props.onClick) child.props.onClick();
                  handleItemClick();
                },
              })
              : null
          )}
        </div>
      </CSSTransition>
    </div>
  );
};

export const DropdownItem: React.FC<DropdownItemProps> = ({
  label,
  icon,
  onClick,
  to,
  divider,
}) => {
  const itemClass = classNames('dropdown-item');

  const content = (
    <div className="dropdown-item-content">
      <div className="dropdown-item-content-left">
        {icon && <span className="dropdown-item-icon">{icon}</span>}
        {label && <span className="dropdown-item-label">{label}</span>}
      </div>
    </div>
  );

  if (divider) return <div className='dropdown-divider' />;

  if (to) {
    return (
      <Link to={to} className={itemClass} onClick={onClick}>
        {content}
      </Link>
    );
  }

  return (
    <div className='dropdown-item__container'>
      <div className={itemClass} onClick={onClick}>
        {content}
      </div>
    </div>
  );
};
