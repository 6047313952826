import React, { useContext, useEffect, useState } from 'react';
import './MainSidebar.less';
import { ReactComponent as IconLogo } from '../../assets/logo_square.svg';
import AvatarPlaceholder from '../../assets/avatar_placeholder.jpg';

import { useNavigate } from 'react-router';
import { showGoProButton } from '../../utils';
import { UserContext } from '../../data/userContext';
import { SettingsModal } from '../settings-modal';
import { Tooltip } from 'antd';
import {
  BookMarked,
  CalendarDays,
  ChevronsUpDown,
  Cloud,
  House,
  ImageUp,
  LayoutDashboard,
  LogOut,
  Play,
  Settings,
  Sparkles,
  Star,
  TvMinimalPlay,
  UserPlus,
} from 'lucide-react';
import { DropdownItem, DropdownMenu } from '../dropdown-menu/DropdownMenu';
import classNames from 'classnames';
import { SidebarContext } from '../../data/sidebarContext';
import axios from 'axios';

const mySpaceItems = [
  {
    label: 'Create Clips',
    icon: <Play size={16} color="var(--text-base)" />,
    href: '/spikes/videos',
    social: false,
  },
  {
    label: 'Live Streams AI',
    icon: <TvMinimalPlay size={16} color="var(--text-base)" />,
    href: '/spikes/streams',
    onlyTwitchUser: true,
  },
];

const personaliseItems = [
  {
    label: 'Asset Management',
    icon: <ImageUp size={16} color="var(--text-base)" />,
    href: '/spikes/asset-management',
    social: true,
  },
];

const publishAndAnalyzeItems = [
  {
    label: 'Social Calendar',
    icon: <CalendarDays size={16} color="var(--text-base)" />,
    href: '/spikes/social',
    social: true,
  },
];

const MainSidebar = ({ isMobile = false }: { isMobile?: boolean }) => {
  const { isSidebarOpen, setIsSidebarOpen } = useContext(SidebarContext);

  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [userAccountDetails, setUserAccountDetails] = useState<any>(null);
  const [loadingUserAccount, setLoadingUserAccount] = useState(false);
  const userContext = useContext(UserContext);
  const subscriptions = userContext?.user?.subscriptions;
  const isGoogleUser = !userContext?.user?.twitch_id;
  const navigate = useNavigate();

  // useEffect(() => {
  //   const isOpen = localStorage.getItem('isSidebarOpen');

  //   if (isOpen === 'false') {
  //     setIsSidebarOpen(false);
  //   }
  // }, []);

  // useEffect(() => {
  //   localStorage.setItem('isSidebarOpen', isSidebarOpen.toString());
  // }, [isSidebarOpen]);

  useEffect(() => {
    const getAccountDetailed = async () => {
      setLoadingUserAccount(true);
      try {
        const response = await axios.get('/streamer/dashboard-subscription');
        if (response?.status === 200) {
          const responseData = response?.data;
          setUserAccountDetails(responseData);
        }
      } catch (error: any) {
        if (error.response) {
          console.log(error.response, 'getAccountDetailed error');
        }
      }
      setLoadingUserAccount(false);
    };
    getAccountDetailed();
  }, []);

  const handleCloseSidebar = () => {
    if (!isMobile) {
      setIsSidebarOpen(false);
    }
  };

  const checkCurrentItem = (href?: string) => {
    if (href) {
      return location.pathname.includes(href);
    }
    return false;
  };

  const handleSettingsClick = () => {
    setShowSettingsModal(true);
  };

  const handleDashboardClick = () => {
    navigate('/dashboard');
  };

  const handleInviteClick = () => {
    navigate('/affiliate-program');
  };

  const handleTutorialsClick = () => {
    navigate('/tutorial');
  };

  const handleLoyaltyClick = () => {
    navigate('/spikes/loyalty-program');
  };

  const handleApiClick = () => {
    navigate('/api');
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    window.location.href = '/';
  };

  const handleGoPro = () => {
    navigate('/subscriptions');
  };

  const generalItems = [
    {
      label: 'Tutorials',
      icon: <BookMarked size={16} />,
      onClick: handleTutorialsClick,
    },
    {
      label: 'Loyalty Program',
      icon: <Star size={16} />,
      onClick: handleLoyaltyClick,
    },
    {
      label: 'Invite a Friend',
      icon: <UserPlus size={16} />,
      onClick: handleInviteClick,
    },
  ];

  if (isMobile)
    generalItems.unshift({
      label: 'Settings',
      icon: <Settings size={16} />,
      onClick: handleSettingsClick,
    });

  return (
    <div className={classNames('main-sidebar', { collapsed: !isSidebarOpen })}>
      <div className="main-sidebar__header">
        <div
          onClick={() => navigate('/')}
          className="main-sidebar__header-button"
        >
          <div className="main-sidebar__header-button-left">
            <div className="main-sidebar__header-avatar">
              <IconLogo />
            </div>
            {isSidebarOpen && (
              <div className="main-sidebar__header-text">
                <div className="main-sidebar__header-title">Spikes</div>
                <div className="main-sidebar__header-subtitle">
                  {(loadingUserAccount && !userAccountDetails?.plan_name) ? (
                    <span style={{ color: 'transparent' }}>--</span>
                  ) : (
                    userAccountDetails?.plan_name || <span style={{ color: 'transparent' }}>--</span>
                  )}
                </div>
              </div>
            )}
          </div>
          {isSidebarOpen && <House size={16} />}
        </div>
      </div>

      <div className="main-sidebar__content">
        <div className="main-sidebar__content-section">
          {isSidebarOpen && (
            <div className="main-sidebar__content-title">My Space</div>
          )}
          {mySpaceItems.map(
            (item) =>
              (item?.onlyTwitchUser ? !isGoogleUser : true) && (
                <NavigationItem
                  key={item.label}
                  label={item.label}
                  icon={item?.icon}
                  href={item?.href}
                  isSidebarOpen={isSidebarOpen}
                />
              ),
          )}
        </div>
        <div className="main-sidebar__content-section">
          {isSidebarOpen && (
            <div className="main-sidebar__content-title">Personalize</div>
          )}
          {personaliseItems.map((item) => (
            <NavigationItem
              key={item.label}
              label={item.label}
              icon={item?.icon}
              href={item?.href}
              isSidebarOpen={isSidebarOpen}
            />
          ))}
        </div>
        <div className="main-sidebar__content-section">
          {isSidebarOpen && (
            <div className="main-sidebar__content-title">Publish & Analyze</div>
          )}
          {publishAndAnalyzeItems.map((item) => (
            <NavigationItem
              key={item.label}
              label={item.label}
              icon={item?.icon}
              href={item?.href}
              isSidebarOpen={isSidebarOpen}
            />
          ))}
        </div>

        <div className="main-sidebar__content-section">
          {isSidebarOpen && (
            <div className="main-sidebar__content-title">General</div>
          )}
          {generalItems.map((item) => (
            <NavigationItem
              key={item.label}
              label={item.label}
              icon={item?.icon}
              onClick={item?.onClick}
              isSidebarOpen={isSidebarOpen}
            />
          ))}
        </div>
      </div>

      <div className="main-sidebar__footer">
        <DropdownMenu
          heading="My Account"
          trigger={
            <div
              onClick={() => setIsSidebarOpen(true)}
              className="main-sidebar__header-button"
            >
              <div className="main-sidebar__header-button-left">
                <div className="main-sidebar__footer-avatar">
                  {userContext?.user?.username && userContext?.user?.username.charAt(0)}
                </div>
                {isSidebarOpen && (
                  <div className="main-sidebar__header-text">
                    <div className="main-sidebar__header-title">
                      {userContext?.user?.username}
                    </div>
                    <div className="main-sidebar__header-subtitle">
                      {userContext?.user?.email}
                    </div>
                  </div>
                )}
              </div>
              <ChevronsUpDown size={16} />
            </div>
          }
          position="top"
        >
          {showGoProButton(userContext?.user) ? (
            <>
              <DropdownItem
                label="Upgrade to Pro"
                icon={<Sparkles size={16} color="var(--bg-brand-primary)" />}
                onClick={handleGoPro}
              />
              <DropdownItem divider />
            </>
          ) : (
            <></>
          )}
          <DropdownItem
            label="Dashboard"
            icon={<LayoutDashboard size={16} />}
            onClick={handleDashboardClick}
          />
          <DropdownItem
            label="API"
            icon={<Cloud size={16} />}
            onClick={handleApiClick}
          />
          <DropdownItem
            label="Settings"
            icon={<Settings size={16} />}
            onClick={handleSettingsClick}
          />
          <DropdownItem divider />
          <DropdownItem
            label="Logout"
            icon={<LogOut size={16} />}
            onClick={handleLogout}
          />
        </DropdownMenu>
      </div>

      {showSettingsModal && (
        <SettingsModal onClose={() => setShowSettingsModal(false)} />
      )}
    </div>
  );
};

interface INavigationItem {
  label: string;
  icon?: React.ReactNode;
  href?: string;
  onClick?: () => void;
  isSidebarOpen: boolean;
}

const NavigationItem = ({
  label,
  icon,
  href,
  onClick,
  isSidebarOpen,
}: INavigationItem) => {
  const navigate = useNavigate();

  const handleClickOnItem = () => {
    if (href) {
      navigate(href);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <Tooltip
      overlayClassName="tooltip"
      title={isSidebarOpen ? '' : label}
      placement="right"
      trigger={['hover']}
    >
      <div
        onClick={handleClickOnItem}
        className="main-sidebar__navigation-item"
      >
        <div className="main-sidebar__navigation-item-left">
          {icon || null}
          {isSidebarOpen && <span>{label}</span>}
        </div>
      </div>
    </Tooltip>
  );
};
export default MainSidebar;
