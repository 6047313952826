import React, { useState, useMemo, useEffect, useContext, createRef } from 'react';
import axios from 'axios';
import './BrandingTab.less';
import { PricingModal } from '../../../components/pricing-modal/PricingModal';
import { checkFileType, getEnabledBrandingForUser, removeFileExtension } from '../../../utils';
import { ReactComponent as IconSearch } from '../../../assets/input-search.svg';
import { UserContext } from '../../../data/userContext';
import { postAudit } from '../../../services/audit.service';
import * as tus from 'tus-js-client';
import Select, { DropdownIndicatorProps, components } from 'react-select';
import { ReactComponent as DropdownIndicatorIcon } from '../../../assets/dropdown-indicator-small-no-color.svg';
import { ReactComponent as IconArrowLeft } from '../../../assets/caret_left.svg';
import { ReactComponent as IconArrowRight } from '../../../assets/caret_right.svg';
import { Carousel, message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Asset } from './BrandingTab';
import { WatermarkSizeHeight } from '../../../constants/content-constants';
import Modal from 'react-modal';
import { IStreamer } from '../../../data/intefaces/streamer.interface';

const positionOptionsTemplate = [
  {
    value: 'top-left',
    label: 'Top left'
  },
  {
    value: 'top-right',
    label: 'Top right',
  },
  {
    value: 'bottom-left',
    label: 'Bottom left'
  },
  {
    value: 'bottom-right',
    label: 'Bottom right'
  },
  {
    value: 'custom',
    label: 'Custom',
    isDisabled: true
  },
]

const positionOptionsEditor = [
  {
    value: 'top-left',
    label: 'Top left'
  },
  {
    value: 'top-right',
    label: 'Top right',
  },
  {
    value: 'bottom-left',
    label: 'Bottom left'
  },
  {
    value: 'bottom-right',
    label: 'Bottom right'
  },
  {
    value: 'custom',
    label: 'Custom',
  },
]

const sizeOptions = [
  {
    value: 'small',
    label: 'Small'
  },
  {
    value: 'medium',
    label: 'Medium'
  },
  {
    value: 'big',
    label: 'Big'
  },
]

const supportedFileTypes = ['image/webp', 'image/jpeg', 'image/png'];

interface IWatermarkBlockProps {
  watermarkFeatureSlider: any;
  handleChooseWatermark: (imageUrl: string, ratio: number, position: string, size?: string) => void;
  handleSelectNoneWatermark: () => void;
  handleDeleteFeatureSlider?: (id: number) => void;
  setSelectedPositionOutside?: (position: string) => void;
  templateMode?: boolean;
  outsideLoading?: boolean;
  user?: IStreamer;
}

export const WatermarkBlock = ({ user, watermarkFeatureSlider, handleDeleteFeatureSlider, setSelectedPositionOutside, handleSelectNoneWatermark, handleChooseWatermark, templateMode = false, outsideLoading }: IWatermarkBlockProps) => {
  const [isPricingModalOpened, setIsPricingModalOpened] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [loadingFileUpload, setLoadingFileUpload] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [allAssets, setAllAssets] = useState<Asset[]>([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedPosition, setSelectedPosition] = useState('top-left');
  const [selectedSize, setSelectedSize] = useState('medium')
  const [showCustomWatermarkModal, setShowCustomWatermarkModal] = useState(false);

  const watermarksList = useMemo(() => [{ id: 'none', type: 'none-watermark', url: '', name: 'none' }, ...allAssets.filter(asset => asset.type === 'watermark')], [allAssets]);
  const positionOptions = useMemo(() => templateMode ? [...positionOptionsTemplate] : [...positionOptionsEditor], [templateMode]);

  const [isFirstSlide, setIsFirstSlide] = useState<boolean>(true);
  const [isLastSlide, setIsLastSlide] = useState<boolean>(false);

  const carouselRef = createRef<any>();

  const userContext = useContext(UserContext);

  const brandingTabEnabledForUser = getEnabledBrandingForUser(user || userContext?.user);

  useEffect(() => {
    fetchAllAssets();
  }, [])

  useEffect(() => {
    if (watermarkFeatureSlider) {
      if (watermarkFeatureSlider?.position) {
        setSelectedPosition(watermarkFeatureSlider.position);
        setSelectedPositionOutside && setSelectedPositionOutside(watermarkFeatureSlider.position);
      }

      setSelectedSize(WatermarkSizeHeight[watermarkFeatureSlider?.mobileDymension?.height] || 'medium');
    } else {
      if (!templateMode) {
        setSelectedPosition('custom');
      }
    }
  }, [])


  const handleOpenPricingModal = () => {
    setIsPricingModalOpened(true);
  };

  const handleClosePricingModal = () => {
    setIsPricingModalOpened(false);
  };

  const fetchAllAssets = async () => {
    const response = await axios.get('/streamer/assets');
    if (response?.data) {
      setAllAssets(response?.data)
    }
  }

  const handleUploadAssetToServer = async (assetUrl: string, type?: string, name?: string, isDefault?: boolean) => {
    if (!brandingTabEnabledForUser) {
      handleOpenPricingModal();
      return;
    }

    setLoading(true);
    const response = await axios.post('/streamer/asset', {
      url: assetUrl,
      type,
      name,
      is_default: isDefault
    }).catch((error) => {
      setLoading(false);
      if (error.response) {
        console.log(error.response);
      }
    });

    if (response?.status === 200) {
      fetchAllAssets();
    } else {
      messageApi.error('Failed to upload your asset. Please try again');
    }
    setLoading(false);
  };

  const handleDeleteAsset = async (id: string, url: string) => {
    setLoading(true);
    const payload = {
      id: id,
    }
    const response = await axios.delete('/streamer/asset', { data: payload });
    fetchAllAssets();

    if (response?.status === 200) {
      messageApi.success('Asset deleted!');
      if (!templateMode && watermarkFeatureSlider && handleDeleteFeatureSlider && watermarkFeatureSlider?.imageUrl === url) {
        handleDeleteFeatureSlider(watermarkFeatureSlider.id)
      }
    } else {
      messageApi.error('Failed to delete your asset. Please try again');
    }

    setLoading(false);
  }


  const dragOverClass = isDragOver ? 'drag-over' : '';

  const handleFileSelect = (e: any) => {
    if (loadingFileUpload) return null;
    try {
      // Get the selected file from the input element
      const file = e.target.files[0];

      if (file && !supportedFileTypes.includes(file?.type)) {
        message.error({
          content: 'Unsupported file type',
        });
        return null;
      }


      setLoadingFileUpload(true);

      if (file) {
        const fileType = checkFileType(file?.type);

        const assetName = removeFileExtension(file?.name);

        // Create a new tus upload
        const upload = new tus.Upload(file, {
          endpoint: process.env.REACT_APP_TUS_SERVER_URL,
          onBeforeRequest: function (req) {
            const xhr = req.getUnderlyingObject();
            xhr.withCredentials = true;
          },
          retryDelays: [0, 3000, 5000, 10000, 20000],
          metadata: {
            filename: file.name,
            filetype: file.type
          },
          onError: function (error: any) {
            setLoadingFileUpload(false);
            postAudit({
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              user_action: `FAILED: Watermark upload to TUS server. File name: ${file?.name || 'Not defined'}, Error: ${JSON.stringify(error)}, File Details: ${JSON.stringify(file)}`,
              user_id: userContext?.user?.id
            });
            console.log('Failed because: ' + error);
          },
          onProgress: function (bytesUploaded: number, bytesTotal: number) {
            setLoadingFileUpload(true);
            const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
            setProgress(Number(percentage));
          },
          onSuccess: function () {
            setLoadingFileUpload(false);
            const assetUrl = upload.url;
            if (assetUrl) {
              setTimeout(() => {
                handleUploadAssetToServer(assetUrl, 'watermark', assetName, true);
              }, 100);
            }
          },
        });

        // Check if there are any previous uploads to continue.
        upload.findPreviousUploads().then(function (previousUploads: any) {
          // Found previous uploads so we select the first one.
          if (previousUploads.length) {
            upload.resumeFromPreviousUpload(previousUploads[0]);
          }

          // Start the upload
          upload.start();
        });
      }
    } catch (error) {
      postAudit({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        user_action: `FAILED: Watermark upload to TUS server, File Details: ${JSON.stringify(file)}, Error: ${JSON.stringify(error)}`,
        user_id: userContext?.user?.id
      });
    } finally {
      setIsDragOver(false);
    }
  };

  const handleFileDragOver = (event: any) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleFileDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    handleFileSelect({ target: { files: event.dataTransfer.files } });
    setIsDragOver(false);
  };

  const handleDragLeave = (event: any) => {
    event.preventDefault();
    setIsDragOver(false);
  };

  const handleSelectWatermark = (imageUrl: string) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      const width = img.width;
      const height = img.height;

      const ratio = width && height ? width / height : 1.7777777778;

      handleChooseWatermark(imageUrl, ratio, selectedPosition, selectedSize);
    };

    img.onerror = (error) => {
      messageApi.error('Failed to get image dymensions');
    };
  };

  const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => {
    return (
      <components.DropdownIndicator {...props}>
        <DropdownIndicatorIcon style={{ height: 10 }} />
      </components.DropdownIndicator>
    );
  };


  const handleCarouselChange = (currentSlide: number) => {
    setIsFirstSlide(currentSlide === 0);
    setIsLastSlide(currentSlide === Math.ceil(watermarksList.length / 4) - 1);
  };

  const handlePrevSlide = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    carouselRef.current.prev();
  };

  const handleNextSlide = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    carouselRef.current.next();
  };

  const handleChangePosition = (position: string) => {
    setSelectedPosition(position);
    setSelectedPositionOutside && setSelectedPositionOutside(position);
    if (watermarkFeatureSlider) {
      handleChooseWatermark(watermarkFeatureSlider?.imageUrl, watermarkFeatureSlider?.desktopDymension?.ratio, position, selectedSize);
    }

    if (position === 'custom' && !templateMode) {
      setShowCustomWatermarkModal(true);
    }
  }

  const handleChangeSize = (size: string) => {
    setSelectedSize(size);

    if (watermarkFeatureSlider) {
      handleChooseWatermark(watermarkFeatureSlider?.imageUrl, watermarkFeatureSlider?.desktopDymension?.ratio, selectedPosition, size);
    }
  }

  return (
    <div className="branding__container">
      {contextHolder}
      <PricingModal isOpen={isPricingModalOpened} handleClosePricingModal={handleClosePricingModal} />
      <Modal
        isOpen={showCustomWatermarkModal}
        className="Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setShowCustomWatermarkModal(false)}
      >
        <div style={{ width: 430 }} className="glass-modal">
          <span onClick={() => setShowCustomWatermarkModal(false)} className="icon-close">X</span>
          <h1>Custom WM position</h1>
          <p>Note: The custom option will not be applied to the template for newly uploaded videos.</p>

          <div className="buttons center">
            <div style={{ minWidth: 170 }} onClick={() => setShowCustomWatermarkModal(false)} className="confirm-btn">
              <span>Got it!</span>
            </div>
          </div>

        </div>
      </Modal>

      {(loading || outsideLoading) &&
        <div className='branding__loading'>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 42, color: '#fff' }} spin />} />
        </div>
      }

      <div className="branding__title">Watermark</div>
      <div
        onDragOver={handleFileDragOver}
        onDrop={handleFileDrop}
        className={`file-upload-wrapper branding-file ${dragOverClass}`}
        onDragLeave={handleDragLeave}
      >
        <input
          type="file"
          accept="image/png, image/jpeg, image/webp"
          id="watermark-input"
          className="file-input"
          onChange={handleFileSelect}
          style={{ display: 'none' }}
        />
        <label htmlFor="watermark-input" className="file-input-label">
          {loadingFileUpload ? `Uploading your file (${progress}%)` : 'Choose a file or drag it here'}
        </label>
      </div>

      <div className='branding__watermark-selects'>
        <div style={{ maxWidth: 150, minWidth: 150 }}>
          <Select
            className="font-size-select branding-select"
            classNamePrefix="font-size-select-prefix"
            value={positionOptions.find(el => el.value === selectedPosition)}
            onChange={({ value }: any) => handleChangePosition(value)}
            options={positionOptions}
            components={{ DropdownIndicator }}
            placeholder='Position'
            isSearchable={false}
          />
        </div>

        {templateMode &&
          <div style={{ maxWidth: 150, minWidth: 150 }}>
            <Select
              className="font-size-select branding-select"
              classNamePrefix="font-size-select-prefix"
              value={sizeOptions.find(el => el.value === selectedSize)}
              onChange={({ value }: any) => handleChangeSize(value)}
              options={sizeOptions}
              components={{ DropdownIndicator }}
              placeholder='Size'
              isSearchable={false}
            />
          </div>
        }
      </div>


      <div style={templateMode ? { maxWidth: 400 } : {}} className='text-insert__carousel-inner' >
        {!isFirstSlide ? <div onClick={handlePrevSlide} className='text-insert__arrow-icon'><IconArrowLeft /></div> : <div style={templateMode ? {} : { width: 40 }} />}
        <div style={{ maxWidth: '100%', width: '76%' }}>

          <Carousel
            ref={carouselRef}
            dots={false}
            infinite={false}
            afterChange={handleCarouselChange}
          >

            {Array.from({ length: Math.ceil(watermarksList.length >= 4 ? watermarksList.length / 4 : 1) }, (value, i) => (
              <div key={i}>
                <div style={{ margin: '0 10px' }} className="editor-grid">
                  <>
                    {watermarksList
                      .slice(i * 4, i * 4 + 4)
                      .map((asset, index) => asset?.type === 'none-watermark' ?
                        <>
                          {watermarksList.length > 1 &&
                            <div
                              className={`presetBox editor-grid__item transparent-background ${Boolean(!watermarkFeatureSlider) && 'selected'}`}
                            >
                              <div onClick={handleSelectNoneWatermark} className='branding__item'>
                                <span>None</span>
                              </div>
                            </div>
                          }
                        </>
                        : (
                          <div
                            key={asset.id}
                            className={`presetBox editor-grid__item transparent-background ${Boolean(watermarkFeatureSlider?.imageUrl === asset.url) && 'selected'}`}
                          >
                            <div
                              onClick={() => handleDeleteAsset(asset.id, asset.url)}
                              className='branding__delete-icon'
                            >
                              <span />
                            </div>
                            <div onClick={() => handleSelectWatermark(asset.url)} className='branding__item'><img src={asset.url} alt={asset.name} /></div>

                          </div>
                        ))}
                  </>
                </div>
              </div>
            ))}
          </Carousel>

        </div>
        {!isLastSlide && watermarksList.length > 4 && <div onClick={handleNextSlide} className='text-insert__arrow-icon' ><IconArrowRight /></div>}
      </div>
    </div>
  )
}